import React, {useState, useMemo} from 'react'
import { IPalletTypeItem } from '../../../../../../modules/API/Defaults/models/pallets'
import SearchFromTheList from '../../../../../../generic/customSearchComponents/SearchFromTheList'

type Props = {
  list: IPalletTypeItem[]
  defaultID: number
  setPalletID: React.Dispatch<React.SetStateAction<number>> | ((id: number) => void),
  required?: boolean
}

const SearchPalletType = ({
  list, defaultID, setPalletID, required
}: Props) => {
  const defaultPallet = list.filter(item => item.Pallete_Type === defaultID)[0]
  const [isOpenDropDown, setIsOpenDropDown] = useState<boolean>(false)
  const [selectedPallet, setSelectedPallet] = useState<IPalletTypeItem | null>(defaultPallet || null)
  const [search, setSearch] = useState<string>(defaultPallet?.Pallete_Desc.trim() || '')
  const handleSearch = (e: string) => {
    if(e === '') {
      setSelectedPallet(null)
      setIsOpenDropDown(false)
      setSearch('')
      setPalletID(0)
      return
    }
    setIsOpenDropDown(true)
    setSearch(e)
  }
  const handleSelect = (item: IPalletTypeItem) => {
    setSearch(item.Pallete_Desc.trim())
    setSelectedPallet(item)
    setIsOpenDropDown(false)
    setPalletID(item.Pallete_Type)
  }
  const render = useMemo(() => {
    if(!list) return []
    return list.filter(item => 
      item.Pallete_Desc.trim().toLocaleLowerCase().includes(search.toLocaleLowerCase())
    )
  
  }, [search, list])
  return (
    <SearchFromTheList 
      isOpenList={isOpenDropDown}
      placeHolder={'חפש סוג משטח'}
      list={render}
      selectFunc={handleSelect}
      searchFunc={handleSearch}
      isNeedFullObject
      input={search}
      itemDisplayName='Pallete_Desc'
      nameIdElement='Pallete_Type'
      required={required}
      size={'full'}
    
    />
  )
}

export default SearchPalletType