
const selectBrodMigdal = (arr, number, value, additional) => {
    try {
        return arr?.filter(e =>  e.Int_ID === number)[0][value]

    } catch (error) {
        return 
    }
}

export default selectBrodMigdal