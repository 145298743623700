import React, { useMemo, useState, useRef, useCallback, useEffect, useContext } from 'react'
import { IReqProduct, ISelectPalletProps } from '../MarketCollectMain';
import { IProductsWithVarietyItem } from '../../../../../../modules/API/Defaults/models/compliteProducts';
import { IGetReuqestResponseNode } from '../../../../../../modules/API/Defaults/models/pallets';
import { IGrowerItem, IGrowerNodeGetResponse } from '../../../../../../modules/API/Defaults/models/growers';
import styled from 'styled-components';
import ControlButton from '../../../../../../generic/buttons/ControlButton';
import SelectFromTheList from '../../../../../../generic/customSearchComponents/SelectFromTheList';
import SearchFromTheList from '../../../../../../generic/customSearchComponents/SearchFromTheList';
import { IPackagesItem } from '../../../../../../modules/API/Defaults/models/packages';
import CircleNumericInput from '../../../../../../generic/inputs/CircleNumericInput';
import selectOnFocus from '../../../../../../modules/autoselect';
import { IProductCashItem, IActionProductCash } from '../../../../../../../store/reducers/market';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store/reducers/rootReducer';
import { ISizeItem } from '../../../../../../modules/API/Products/models/size';
import TypeCalcSelect from './generic/TypeCalcSelect';
import useDataFetcher from '../../../knisot/hooks/useFetchData';
import { Market } from '../../../../../../modules/API/Market/market';
import useScannedDataHandler from '../../../kosherTeudot/Hooks/useScannedDataHandler';
import { Gathering } from '../../../../../../modules/API/Gathering/gathering';
import { BarCodeIcon, UserIcon } from '../../../../../../generic/icons/Icons';
import ScanInput from '../../../kosherTeudot/Components/ScanInput';
import { IResponseSrikaNode } from '../../../../../../modules/API/Gathering/models/srika';
import CircleActiveFilterBtn from '../../../../../../generic/particles/CircleActiveFilterBtn';
import CheckBoxWithTitle from '../../../../../../generic/particles/CheckBox';
import { ContextModal } from '../../../../../../modules/modal/modalContext';

type Props = {
  data: ISelectPalletProps;
  dataGrowers: IGrowerNodeGetResponse;
  dataPallets: IGetReuqestResponseNode;
  packageList: IPackagesItem[],
  sellerID: string;
  defaultPallet: number;
  defaultPackage: number;
  createProduct: (obj: IReqProduct) => void;
  cashProduct: (data: IProductCashItem | null) => void;
  dataProductsWithVariety: IProductsWithVarietyItem[];
  getSizes: (productID: number) => void;
  sizeData: ISizeItem[];
  isWorker: boolean,
  overweightDelta: number
}
interface IRef {
  focusInput: () => void;
  blurInput: () => void;
}

const AddProductModal = ({
  data, dataGrowers, dataPallets, dataProductsWithVariety, getSizes,
  packageList, createProduct, defaultPallet, defaultPackage, 
  cashProduct, sizeData, isWorker, overweightDelta
}: Props) => {
  
  const marketCash: IActionProductCash = useSelector((state: RootState) => state.marketReucer);
  const { pallet, client, name, seller } = data;
  console.log(client)
  const chashRef = useRef(marketCash.data ? marketCash.data[client] : null)
  const refBody = useRef<IRef>(null)

  const defPack = packageList.find(
    (item) => 
      item.PackID === 
        (chashRef.current ? chashRef.current.packageID : defaultPackage)
  );

  const defPackQty = chashRef.current ? chashRef.current.packageQty : 0;
  const defPrice = chashRef.current ? chashRef.current.price : 0;
  const defWeight = chashRef.current ? chashRef.current.weight : 0;
  const defReffernce = chashRef.current ? chashRef.current.reference : "";
  const defGrower = dataGrowers.growers.find(grower => grower.GrowerID === chashRef.current?.growerID) || null;
  
  const defProduct = dataProductsWithVariety.find(product => 
    product.ProductID === chashRef.current?.productID &&
      product.VarietyID === chashRef.current?.varietyID
  ) || null;
  const [modal, setModal] = useContext(ContextModal)
  const [selectedProduct, setSelectedProduct] = useState<IProductsWithVarietyItem | null>(defProduct);
  
  const [inputProduct, setInputProduct] = useState<string>(
    () => defProduct ?
       `${defProduct?.ProductDesc?.trim().toLowerCase()} ${defProduct?.VarietyDesc.trim().toLowerCase()}` : ""
  );

  const [isOpenListProduct, setIsOpenList] = useState<boolean>(false);

  const [selectedGrower, setSelectedGrower] = useState<IGrowerItem | null>(defGrower);
  const [inputGrower, setInputGrower] = useState<string>(() => defGrower?.GrowerName.trim() || "");
  const [isOpenListGrower, setIsOpenListGrower] = useState<boolean>(false);

  const [selectedPackage, setSelectedPackage] = useState<IPackagesItem | null>(() => defPack || null);
  const [inputPackage, setInputPackage] = useState<string>(() => defPack?.Pack_Name?.trim() || "");
  const [isOpenListPackage, setIsOpenListPackage] = useState<boolean>(false);
  const [packageQty, setPackageQty] = useState<number>(defPackQty);
 
  const [price, setPrice] = useState<number>(defPrice);
  const [remark, setRemark] = useState<string>('');
  const [reffernce, setReffernce] = useState<string | number>(defReffernce);
  
  // const [weightNet, setWeightNet] = useState<number>(0);
  const [weightGross, setWeightGross] = useState<number>(defWeight);

  const [selectedPallete, setSelectedPallete] = useState<number>(defaultPallet);
  const [selectedSize, setSelectedSize] = useState<number>(0);
  const [typeCalc, setTypeCalc] = useState<number>(0);
  const [isDefaultGrowerList, setIsDefaultGrowerList] = useState<boolean>(false);
  const [isNeedtoCalcPallet, setIsNeedtoCalcPallet] = useState<boolean>(false);
  const [entryData, setEntryData] = useState<IResponseSrikaNode | null>(null)
  const {
    data: growerList,
    setData: setGrowerList
  } = useDataFetcher<IGrowerItem[]>(
    Market.getGrowerListByProductID, `${selectedProduct?.ProductID || 0}`
  )
  useEffect(() => {
    if(!selectedProduct) return
    getSizes(selectedProduct.ProductID)
  },[selectedProduct])
  // update cash
  const updateCash = useCallback(cashProduct, [cashProduct])
  useMemo(() => {
    let tempCash: IProductCashItem = {
      growerID: selectedGrower?.GrowerID || 0,
      productID: selectedProduct?.ProductID || 0,
      varietyID: selectedProduct?.VarietyID || 0,
      packageID: selectedPackage?.PackID || 0,
      packageQty: packageQty,
      price: price,
      weight: weightGross,
      reference: Number(reffernce),
    }
    updateCash(tempCash)
  }, [
    selectedGrower, selectedProduct, selectedPackage, packageQty,
    price, weightGross, reffernce
  ])
  useEffect(() => {
    // console.log(overweightDelta)
    // console.log(entryData)
    if(!entryData || overweightDelta === 0 ) return
    const overWeight = (weightGross / (entryData.entryLine[0].AvgWeightPerPack * packageQty) * 100) - 100 
    // console.log(Math.round(overWeight),  overweightDelta)
    if(Math.round(overWeight) > overweightDelta) {
      setModal({
        open: true,
        mood: false,
        value: "המשקל הממוצע שונה מכניסה"
      })
      setTimeout(() => {
        setModal({
          open: false,
          mood: false,
          value: '',
        })
      }, 6000)
    }
  }, [packageQty, weightGross, entryData, overweightDelta])

  const handleSwitchGrowerList = () => {
    setIsDefaultGrowerList(!isDefaultGrowerList)
    setGrowerList(dataGrowers.growers)
  }

  // Package
  const handleSearchPackage = (e: string) => {
    setIsOpenListPackage(true)
    setInputPackage(e)
    if (!e) {
      console.log(null)
      setSelectedPackage(null)
    }
  }

  const handleSelectPackage = (item: IPackagesItem) => {
    setSelectedPackage(item)
    setInputPackage(item.Pack_Name.trim())
    setIsOpenListPackage(false)
  }

  const handlePackageQty = (e: number) => {
    setPackageQty(Math.round(Number(e)))
  }
  // !Package

  // Grower
  const handleSearchGrower = (e: string) => {
    setIsOpenListGrower(true)
    setInputGrower(e)
    if (!e) {
      console.log(null)
      setSelectedGrower(null)
    }
  }

  const handleSelectGrower = (item: IGrowerItem) => {
    setSelectedGrower(item)
    setInputGrower(item.GrowerName.trim())
    setIsOpenListGrower(false)
  }
  // ! Grower

  // PRODUCT
  const handleSearchProduct = (e: string) => {
    setIsOpenList(true)
    setInputProduct(e)
    if (!e) {
      console.log(null)
      setSelectedProduct(null)
    }
  }

  const handleSelectProduct = (item: IProductsWithVarietyItem) => {
    // if (!item.fullDescription) return

    setSelectedProduct(item)
    setInputProduct(`${item.ProductDesc?.trim().toLowerCase()} ${item.VarietyDesc.trim().toLowerCase()}`)
    setIsOpenList(false)
    handleSelectTypeCalc(item.CalculationMethod)
    // clear grower data if product changed
    setSelectedGrower(null)
    setInputGrower('')
    setEntryData(null)
  }
  //  !PRODUCT
  console.log(typeCalc)
  // PALLETE
  const handleSelectPallet = (palletType: number) => {
    setSelectedPallete(palletType);
  }
  // !PALLETE

  // SIZE
   const handleSelectSize = (size: number) => {
    setSelectedSize(+size);
   }
  // Price 
  const handlePrice = (e: number) => {
    setPrice(Number(e))
  }
  // !Price

  // Reffernce
  const handleReffernce = (e: string) => {
    setReffernce(e)
  }
  // !Reffernce
  const handleRemark = (e: string) => {
    setRemark(e)
  }
  // Weight
  // const handleWeightNet = (e: number) => {
  //   setWeightNet(Number(e))
  // }

  const handleWeightGross = (e: number) => {
    setWeightGross(Number(e))
  }
  // !Weight

  const handleCreateProduct = () => {
    if(!selectedProduct || !selectedGrower || !selectedPackage || typeCalc === null || typeCalc === -1 ) return
    const palletWeight = dataPallets.pallets.find(item => item.Pallete_Type === selectedPallete)?.Tare_Weight || 0
    const finalWeight = !isNeedtoCalcPallet ? weightGross : weightGross - palletWeight
    try {
      createProduct({
        selectedProduct: selectedProduct,
        GrowerID: selectedGrower.GrowerID,
        PalletNum: pallet === 0 ? 999999 : pallet,
        Qty: packageQty,
        // TODO: после изменений у бори, писать только нетто
        Weight: finalWeight,
        PalletType: selectedPallete || 0,
        CustomerID: client,
        PackID: selectedPackage.PackID,
        Price: price,
        Type_SaleCalculation: typeCalc || 0,
        Remarks: remark,
        EntryNum: input,
        SizeID: sizeData.find(item => item.Code === selectedSize)?.Description.trim() || '',
        CustomerSeller: `${client}-${seller}`,
      })
      cashProduct(null)
    } catch (error) {

    } 
  }
  console.log(`${client}-${seller}`)
  const renderGrower = useMemo(() => {
    if(!growerList) return []
    return growerList.filter((item) => {
      return item.GrowerName?.toLowerCase().includes(inputGrower.toLowerCase())
    })
  }, [growerList, inputGrower])

  const renderProduct = useMemo(() => {
    if(!dataProductsWithVariety) return []
    return dataProductsWithVariety.filter((item) => {
      return `${item.VarietyDesc.trim().toLowerCase()} ${item.ProductDesc?.trim().toLowerCase()} `
        .includes(inputProduct.toLowerCase())
    })
  }, [dataProductsWithVariety, inputProduct])

  const renderPackage = useMemo(() => {
    if(!packageList) return []
    return packageList.filter((item) => {
      return item.Pack_Name?.toLowerCase().includes(inputPackage.toLowerCase())
    })
  }, [packageList, inputPackage])
  
  const handleSelectTypeCalc = useCallback((type: number) => {
    setTypeCalc(type)
  }, [])
  const [input, setInput] = useState<string>('')

  const handleScanBarcode = async () => {
    // if (!refBody?.current) return
    let res = {} as IResponseSrikaNode
    try {
      const permanentCode = input.split('#')

      if(permanentCode.length > 1) {
        res = await Gathering.getPermanentKnisaByBarcode(permanentCode[1])
      } else {
        res = await Gathering.getKnisaByBarcode(input)
      }

      const data = res.entryLine[0]
      handleSelectProduct({
        ProductID: data.ProductID,
        VarietyID: data.VarietyID,
        ProductDesc: data.ProductDesc,
        VarietyDesc: data.VarietyDesc,
        GeneralProductID: 0,
        CalculationMethod: data.ProductCalculationMethod,
      })
      handleSelectTypeCalc(data.ProductCalculationMethod)
      handleSelectGrower({
        GrowerID: data.GrowerID,
        GrowerName: data.GrowerName,
        AccounID: 'string',
        Active: true,
        ClientID: 0,
        Exporter_Num: 0,
        Farm_Num: 0,
        FieldMan: 0,
        GGN: 'string',
        GroupID: 0,
        Kanat_Num: 0,
        Muaza_Num: 'string',
        Pay_Discharge: true,
        Pay_Levies: true,
        Pay_Logistics: true,
        Payment_Method: 0,
        PickUp: true
      })
      handleSelectSize(data.SizeNum)
      const pack = packageList.filter(item => item.PackID === data.PackID)[0]
      setEntryData(res)
      
      if(!pack) return
      handleSelectPackage(pack)
    } catch (error) {
      setModal({
        open: true,
        mood: false,
        value: "לא נמצא פריט עם קוד זה"
      })
      setTimeout(() => {
        setModal({
          open: false,
          mood: false,
          value: '',
        })
      }, 6000)
    } finally {
      refBody.current && refBody.current.blurInput()
    }
  }
  useEffect(() => {
    // if(!refBody.current) return
    refBody.current && refBody.current.focusInput()
  }, [])
  // useScannedDataHandler(refBody, handleScanBarcode)
  return (
    <MainWrapper>
      <SelectWrapper>
      <ScanInput
          enterFunc={handleScanBarcode}
          ref={refBody}
          input={input}
          setInput={setInput}
          icon={<BarCodeIcon />}
        />
        <SelectorWrapper >
          <Title>
            <p>
            בחר פריט
            </p>
            {
              selectedProduct &&
              <p>
              בחר גודל  
              </p>
            }
          </Title>
          <SelectItemWrapper>
          {
            selectedProduct && sizeData &&
            <SelectFromTheList 
              lable={'בחר גודל'}
              list={sizeData}
              dispalyFieldName={'Description'}
              itemKey={'Code'}
              fieldValue={'Code'}
              selectedValue={selectedSize}
              selectFunction={handleSelectSize}
              size={"small"}
              disableSelectedMark
              disabled={!!entryData}
              
              />
            }
          <SearchFromTheList
            input={inputProduct}
            list={renderProduct}
            itemDisplayName='ProductDesc'
            itemSecondaryName={'VarietyDesc'}
            nameIdElement='id'
            isNeedFullObject
            placeHolder={'חפש פריט'}
            searchFunc={handleSearchProduct}
            selectFunc={handleSelectProduct}
            isOpenList={isOpenListProduct}
            size={'full'}
            required={!selectedProduct}
            disabled={!!entryData}
    
            />
            </SelectItemWrapper>
        </SelectorWrapper>
        {
          selectedProduct &&
            <SelectorWrapper >
              <Title>
                <p>
                  בחר מגדל
                </p>
                <p>
                  כולם
                </p>
              </Title>
              <SelectItemWrapper>
                <CircleActiveFilterBtn
                  func={handleSwitchGrowerList}
                  withAction
                >
                  <UserIcon />
                </CircleActiveFilterBtn>
                <SearchFromTheList
                  input={inputGrower}
                  list={renderGrower}
                  itemDisplayName='GrowerName'
                  nameIdElement='GrowerID'
                  isNeedFullObject
                  placeHolder={'חפש מגדל'}
                  searchFunc={handleSearchGrower}
                  selectFunc={handleSelectGrower}
                  size={'full'}
                  isOpenList={isOpenListGrower}
                  required={!selectedGrower}
                  disabled={!!entryData}
                />

              </SelectItemWrapper>
            </SelectorWrapper>
        }
        <SelectorWrapper >
          <Title>
            <p>
              בחר אריזה
            </p>
            <p>
              כמות  
            </p>
          </Title>
          <SelectItemWrapper>
            <CircleNumericInput 
              defaultValue={packageQty}
              changeFunc={handlePackageQty}
              isAllowNegative
              step={1}
            />
            <SearchFromTheList
              input={inputPackage}
              list={renderPackage}
              itemDisplayName='Pack_Name'
              nameIdElement='PackID'
              isNeedFullObject
              placeHolder={'חפש אריזה'}
              searchFunc={handleSearchPackage}
              selectFunc={handleSelectPackage}
              isOpenList={isOpenListPackage}
              size={'full'}
            
              required={!selectedPackage}

            />
          </SelectItemWrapper>
        </SelectorWrapper>
        {
          selectedProduct &&
            <SelectorWrapper>
              <Title>
                <p>
                  אופן חישוב
                </p>
              </Title>
              <TypeCalcSelect
                handleSelect={handleSelectTypeCalc}
                defaultType={typeCalc}
              />
            </SelectorWrapper>
        }
        <SelectorWrapper >
          <Title>
            <p>
              משקל 
            </p>
            {
              !isWorker &&
                <p>
                  מחיר  
                </p>
            }
          </Title>
          <SelectItemWrapper>
            {
              !isWorker && 
                <Input
                  type='number'
                  defaultValue={price}
                  onChange={e => handlePrice(Number(e.target.value))}
                  onClick={selectOnFocus}
                  onWheel={(e) => e.currentTarget.blur()}
                />
            }
            <Input
              type='number'
              defaultValue={weightGross} 
              onChange={e => handleWeightGross(Number(e.target.value))}
              onClick={selectOnFocus}
              onWheel={(e) => e.currentTarget.blur()}
            />
          </SelectItemWrapper>
        </SelectorWrapper>
        {
          // for prod set to equal 0
          pallet === 0 &&
          <SelectorWrapper>
            <Title>
              בחר סוג משטח
            </Title>
            <SelectFromTheList
              lable={'בחר סוג משטח'}
              list={dataPallets.pallets}
              dispalyFieldName={'Pallete_Desc'}
              itemKey={'Pallete_Type'}
              fieldValue={'Pallete_Type'}
              selectedValue={selectedPallete}
              selectFunction={handleSelectPallet}
              size={"full"}
              disableSelectedMark
            />
            
            <CheckBoxWithTitle 
              title="כולל טרת משטח"
              defaultState={isNeedtoCalcPallet}
              stateFunc={setIsNeedtoCalcPallet}
            />
          </SelectorWrapper>
        }
        <SelectorWrapper >
          <TextAreaWrapper>
            <Title>
              הערות
            </Title>
            <TextArea
              placeholder='הערות'
              tabIndex={8}
              onChange={e => handleRemark(e.target.value)}
            />

          </TextAreaWrapper>
        </SelectorWrapper>
       
      </SelectWrapper>
      <ControlButton
        lable={'שמור פריט'}
        handleClick={handleCreateProduct}
      />
    </MainWrapper>
  )
}

const MainWrapper = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 1em;
  padding-bottom: 0.5em;
  overflow: hidden;
`

const SelectWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 1em;
  padding: 0.5em; 
  overflow-y: auto;
  ::-webkit-scrollbar{
    width: 0;
  }
`

const SelectorWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  padding: 0.5em;
  border-radius: 30px;
  background: rgb(233,240,240, 0.2)
`

const SelectItemWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5em;
  justify-content: space-between;
`

const Input = styled.input`
  width: 100%;
  height: 3em;
  border-radius: 30px;
  background: #EFF3F4;
  border: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  text-align: end;
  padding: 0 1em;
  font-size: 1.2em;
  color: #757575;
`

const Title = styled.div`
  text-align: end;
  color: #297F76;
  font-weight: 600;
  padding: 0 0.5em;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse
`
const TextAreaWrapper = styled.div`
  background: #FBFCFC;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  padding: 0.5em;
  border-radius: 30px;
  color: #297F76;

`
const TextArea = styled.textarea`
  appearance: none;
  border-radius: 30px;
  border: none;
  width: 100%;
  background: #EFF3F4;
  padding: 0.5em 1em;
  font-size: 1.1em;
  font-family: 'Assistant', sans-serif;
  outline: none;
  direction: rtl;
  color: #757575;
  ::placeholder {
    opacity: 0.5;
  }
  ::-webkit-scrollbar {
    width: 0;
  }
`
export default AddProductModal