import axios from 'axios'
import styled from 'styled-components'
import LikutWorkerItem from './LikutWorkerItem'
import { useDispatch, useSelector } from 'react-redux'
import { MainWrapper } from '../../secondbar/mainbar2'
import Title from '../../../../../generic/Title/Title'
import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import WorkersSelectList from './workersSelectList/WorkersSelectList'
import { ContextModal } from '../../../../../modules/modal/modalContext'
import DatePickerModule from '../../../../../generic/datePicker/datePicker'
import { CHASH_WORKERS_LISTS, LOAD, LOAD_END, LOGOUT } from '../../../../../../store/actions/actionsTypes'
import { CloseIcon, FilterIcon, ReloadIcon, SettingMiksher } from '../../../../../generic/icons/Icons'
import { passOrderRowsIntoOrder } from '../../../../../generic/utils/passOrderRowsIntoOrder'
import ModalFilterAzmanot, { FilterContainer, MainModalWrapper } from './filterAzmanot/ModalFilterAzmanot'
import OrderGetheringItemWithDetailes from '../../../../../generic/cards/OrderGethering/OrderGetheringItemWithDetailes'
import { EDIT_ORDER_ROW, GET_CUSTOMER_GROUP, GET_CUSTOMER_LIST, GET_DELIVERYCODES, GET_PRODUCT_TYPES, GET_SELLERS, GET_TRANSPORTAREAS, LOAD_ORDERS, PATCH_PALLET, WORKERS } from '../../../../../modules/API/settings'
import ColumModal from '../../../../../generic/Layouts/ColumModal'
import LikutRemarkModal from '../likutSection/LikutRemarkModal'
import { Defaults } from '../../../../../modules/API/Defaults/defaults'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import { Orders } from '../../../../../modules/API/Orders/orders'

export const SectionMain = styled.div`
    position: relative;
    background: #e9f0f0;
    width: 100%;
    height: 100%;
    padding: 0.5em;
    /* @media screen and (min-width: 770px) {
        position: relative;
    }
    @media screen and (max-width: 400px) {
        padding: 0;
    } */
`

export const SectionMainContainer = styled(MainWrapper)`
    max-width: 1600px;
    min-width: 1200px;
    background: #eff3f4;
    padding-bottom: 1em;
    /* overflow: hidden; */
    /* color: ; */
    -webkit-box-shadow: 0px 1px 2px 2px rgba(161,161,161,0.1); 
    box-shadow: 0px 1px 2px 2px rgba(161,161,161,0.1);
    @media screen and (max-width: 770px) {
        min-width: 100%;
    }
`

export const BodyContainer = styled.div`
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 0 30px 30px 30px;
    padding: 1em;
    display: flex;
    flex-flow: row-reverse;
    justify-content: center;
    align-items: center;
    gap: 1em;
    -webkit-box-shadow: -2px 8px 2px -6px rgb(41 127 118 / 71%);
    box-shadow: -2px 8px 2px -6px rgb(41 127 118 / 71%);
    
`

export const BodyGroupContainer = styled.div`
    height: 100%;
    width: 100%;
    border-radius: 20px;
    padding: 0.5em;
    /* background: blanchedalmond; */
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5em;
`

export const BodyGroupTitleWrapper = styled.div`
    position: relative;
    background: #eff3f4;
    width: 100%;
    min-height: 4em;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: -2px 8px 2px -6px rgb(41 127 118 / 71%);
    box-shadow: -2px 8px 2px -6px rgb(41 127 118 / 71%);

`

export const BodyGroupContentWrapper = styled.div`
    background: ${props => props.isAllowDrop ? 'rgba(128,224,129,0.5)' : '#eff3f4'} ;
    width: 100%;
    height: 90%;
    border-radius: 20px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    gap: 0.5em;
    overflow-y: auto;
    padding: 0.5em;
    transition: 0.3s;
    ::-webkit-scrollbar {
        width: 0;
    }
`

export const IconWrapperOrders = styled.div`
    position: absolute;
    left: 0.5em;
    color: #297f76;
    background: #fff;
    width: 3em;
    border-radius: 50%;
    height: 3em;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: -2px 8px 2px -6px rgb(41 127 118 / 71%);
    box-shadow: -2px 8px 2px -6px rgb(41 127 118 / 71%);
    transition: 0.5s;
    transform: ${props => props.primal ? 'rotate(360deg)' : 'rotate(0)'};
`
export const IconWrapperOrdersSecond = styled.div`
    position: absolute;
    right: 0.5em;
    color: #297f76;
    background: ${props => props.primal ? '#eff3f4' : '#fff'} ;
    width: 3em;
    border-radius: 50%;
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    -webkit-box-shadow: -2px 8px 2px -6px rgb(41 127 118 / 71%);
    box-shadow: -2px 8px 2px -6px rgb(41 127 118 / 71%);
`

export const BodyGroupInnerContainer = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    gap: 0.5em;
`
export const DatePickerWrapper = styled.div`
    background: #fff;
    position: absolute;
    left: 0.5em;
    top: 0.1em;
    width: 10em;
    display: flex;
    justify-content: center;
    padding: 0.3em 1em;
    border-radius: 30px;
    z-index: 2;
`

export default function LikutSetWorkers() {
    
    const dispatch = useDispatch();
    const workersListStatic =  useSelector(list => list.defaults?.workerList).filter(e => e.Active && e.Role_108 === 3)
    const defaultsTable = useSelector(e => e.defaults?.tableOfTables);
    const transportAreas = useSelector(e => e.defaults.transportAreas.array)
    const cashedWorkers = useSelector(e => e.workerOrders)
    const marketAreaList = useMemo(() => defaultsTable.filter(e => e.MainCode === 300 && e.SecondCode !== 0), [defaultsTable]) 
    const workerGroup = useMemo(() => defaultsTable.filter(e => e.MainCode === 311 && e.SecondCode !== 0), [defaultsTable]) 
    const getToken = useSelector(token => token.mainReducer.token);
    const statusAzmanotToShow = useSelector(item => item.defaults?.defaults?.filter(i => i.Int_ID === 444)[0]);
    const [modal, setModal] = useContext(ContextModal);
    const workersHashTable = useMemo(() => workersListStatic.reduce((acc, cur) => {
        acc[cur.WorkerId] = cur.WorkerName?.trim()
        return acc
    }, {}), [workersListStatic]) 
    // console.log(workersHashTable)
    const [workersList, setWorkersList] = useState(() => cashedWorkers?.restWorkers?.length > 0 ? cashedWorkers.restWorkers : workersListStatic);
    const [workersListDefault, setWorkersListDefault] = useState(() => cashedWorkers?.restWorkers?.length > 0 ? cashedWorkers.restWorkers : workersListStatic);
    const [azmanotList, setAzmanotList] = useState([]);
    const [azmanotListDefault, setAzmanotListDefault] = useState([]);
    const [currentWorkerAzmanot, setCurrentWorkerAzmanot] = useState([]);
    const [workerId, setWorkerId] = useState(null);
    const [filterFunc, setFilterFunc] = useState(null);
    const [isSpin, setIsSpin] = useState(false);
    
    const [confermedWorkers, setConfermedWorkers] = useState(() => cashedWorkers?.selectedWorkers || []);
    const [confermedWorkersDefault, setConfermedWorkersDefault] = useState(() => cashedWorkers?.selectedWorkers || []);
    const [deliveryCodes, setDeliveryCodes] = useState([]);
 
    const [customerGroup, setCustomerGroup] = useState([]);
    const [sellerList, setSellerList] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const customersDict = useSelector(state => state.defaults?.clientList?.hashTable);
    const [prodTypes, setProdTypes] = useState([]);
    const [isOpenOrderFilter, setIsOpenOrderFilter] = useState(false);
    const [isOpenWorkerFilter, setIsOpenWorkerFilter] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [droppableItem, setDroppableItem] = useState('');
    const defaultPackageList = useSelector(info => info.defaults.packageList);

    const [isOpenRemarkModal, setIsOpenRemarkModal] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null)

    
    const memoizedOrders = useMemo(() => {
        console.log('renderMEMO')
        return azmanotList
    }, [azmanotList])
    // const date = format(startDate, "yyyyMMdd");
    
    const openEditRemarkLikut = (item) => {
        setSelectedOrder(item);
        setIsOpenRemarkModal(!isOpenRemarkModal)
    }
    
    const handleModalOrders = () => {
        setIsOpenOrderFilter(!isOpenOrderFilter);
    }
    const handleModalWorkers = () => {
        setIsOpenWorkerFilter(!isOpenWorkerFilter);
    }
 
    const onSelectWorker = (id) => {
        console.log('on select worker')
        const filteredAzmanot = azmanotList.filter(e => e.WorkerID !== id);
        const filteredAzmanotByWorker = azmanotListDefault.filter(e =>
            e.WorkerID === id 
        ).sort((a, b) => a.PickupOrder - b.PickupOrder)
        console.log(filteredAzmanotByWorker);
        setAzmanotList(filteredAzmanot);
        setCurrentWorkerAzmanot(filteredAzmanotByWorker);
    }

    const selectIconForPalletType = (packID) => {

        if (!packID) return ''
        let filtered = defaultPackageList.filter(e => e?.PackID === packID);
        
        let result = filtered[0]?.Pack_Type;
        return result

    }

    const getProdTypes = async () => {
        try {
            let response = await axios.get(`${GET_PRODUCT_TYPES}`, {
                headers: {
                    Authorization: 'Bearer ' + getToken
                }
            })
            setProdTypes(response.data.productsGroups)
            
        } catch (err) {
            console.log(err.response)
            if (err.response.status !== 404) {
                setModal({
                    open: true,
                    value: err.response.data.message,
                    mood: false
                })
                setTimeout(() => {
                    setModal({
                        ...modal,
                        open: false
                    })
                }, 3000)
                if(err.response?.data.message === 'jwt malformed' || err.response?.data.message === 'jwt expired') {
                    dispatch({type: LOGOUT, payload: false});
                }
            }

        }

    }
    
    const getSellers = async () => {
        try {
            let res = await axios.get(GET_SELLERS, {
                headers: {
                    'Authorization': 'Bearer ' + getToken
                }
            })
            // console.log(res.data.sellers);
            setSellerList(res.data.sellers)
        } catch (err) {
            if(err.response?.data.message === 'jwt malformed' || err.response?.data.message === 'jwt expired') {
                dispatch({type: LOGOUT, payload: false});
            }
            console.log(err.response)
        }
    }

    const getClients = async () => {
        try {
            let res = await axios.get(GET_CUSTOMER_LIST, {
                headers: {
                    'Authorization': 'Bearer ' + getToken
                }
            })
            // console.log(res.data)
            setCustomerList(res.data.customers)
        } catch (err) {
            console.log(err.response)
        }
    }

    const putWorkersAzmanot = async (action = 'add', object) => {
        try {
            let res = await axios({
                url: WORKERS,
                method: 'put',
                headers: {
                    'Authorization': 'Bearer ' + getToken
                },
                data: {
                    "Action": action === 'add' ? '+' : "-",
                    'WorkerID': workerId,
                    "Orders": action === 'add' ? currentWorkerAzmanot : [object]
                }
            })
            // console.log(res.data)
        } catch (err) {
            console.log(err?.response)
        }
    }
    const getAzmanotList = async (startDate) => {
        dispatch({ type: LOAD })
        try {
            const res = await Orders.getOrdersWithDetails(startDate, statusAzmanotToShow.Text_Value)
            const hashDetails = passOrderRowsIntoOrder(res.OrderDetails)
            let final = res.Orders.map(item => {
                        item.detailes = hashDetails[item?.OrderID] || []
                        return item
                    }  
                )

            let filtered = final.filter(e => e.WorkerID === 0)
            setAzmanotListDefault(final)
    
            if (!filterFunc) {
                setAzmanotList(filtered)
            } 
            else {
                filterFunc.get(filtered)
            }
            // dispatch({ type: LOAD_END })
            // console.log(res.data.orders)
           
        
        } catch (err) {
            // dispatch({ type: LOAD_END })
            console.log(err.response)
        } finally {
            dispatch({ type: LOAD_END })
        
        }
    }
 

    const getCustomerGroup = async () => {
        try {
            let res = await Defaults.getCustomersGroup()
            setCustomerGroup(res.groups)
            // console.log(res.data)
        } catch (err) {
            console.log(err?.response)
        }
    }

    const getDeliveryCodes = async () => {
        try {

            let res = await axios.get(GET_DELIVERYCODES, {
                headers: {
                    'Authorization': 'Bearer ' + getToken
                }
            })

            // console.log(res.data)
            setDeliveryCodes(res?.data?.deliveryCodes)
        } catch (err) {
            console.log(err)
        }
    }
    
    useEffect(() => {
        getDeliveryCodes();
        getCustomerGroup();
        getSellers();
        getClients();
        getProdTypes()
    }, [])
   
    useEffect(() => {
        console.log('useEffect render order fetch')
        if(startDate.toString().length > 8) return
        getAzmanotList(startDate, startDate);
        
    }, [startDate])

    // clear worker and azmanot for worker state if confermed workers list is empty
    useEffect(() => {
        if (confermedWorkers.length === 0) {
            setWorkerId(null);
            setCurrentWorkerAzmanot([])
        }
    }, [confermedWorkers])

    const onDragEnd = result => {
        const { destination, source, draggableId } = result;

        if (!destination) return;
        if (destination.droppableId === source.droppableId && destination.index === source.index) return;
        if (!workerId) {
            setModal({
                open: true,
                value: 'בחר מלקט',
                mood: false
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
            return
        }

        setDroppableItem(draggableId)

        if (source.droppableId === 'droppble1') {
            let a = azmanotList.splice(source.index, 1);
            if (destination.droppableId === 'droppble1') {
                azmanotList.splice(destination.index, 0, ...a);
                // console.log(source)
                setAzmanotList(azmanotList)
            }
            else if (destination.droppableId === 'droppble2') {
                let setterWorker =
                {
                    ...a[0],
                    WorkerID: workerId
                }
                currentWorkerAzmanot.splice(destination.index, 0, setterWorker);
                // azmanotListDefault.splice(destination.index, 0, setterWorker)
                // console.log(setterWorker)
                currentWorkerAzmanot.forEach((e, index) => {
                    e.PickupOrder = index + 1;
                })
                let defaultWorker = azmanotListDefault.map((e) => {

                    if (e.OrderID === a[0].OrderID) {
                        e.WorkerID = workerId
                        e.PickupOrder = destination.index + 1
                    }
                    return e
                });
            
                setAzmanotListDefault(defaultWorker)
                setCurrentWorkerAzmanot(currentWorkerAzmanot)
                putWorkersAzmanot()
            }
        }
        if (source.droppableId === 'droppble2') {
            console.log('asd')
            let a = currentWorkerAzmanot.splice(source.index, 1);
            if (destination.droppableId === 'droppble2') {

                currentWorkerAzmanot.splice(destination.index, 0, ...a);
                currentWorkerAzmanot.forEach((e, index) => {
                    e.PickupOrder = index;
                })
                console.log(currentWorkerAzmanot)
                setCurrentWorkerAzmanot(currentWorkerAzmanot);
                putWorkersAzmanot();
            }
            else if (destination.droppableId === 'droppble1') {
                let setterWorker =
                {
                    ...a[0],
                    WorkerID: 0

                }
                azmanotList.splice(destination.index, 0, setterWorker);
                let defaultWorker = azmanotListDefault.map(e => {

                    if (e.OrderID === a[0].OrderID) {
                        e.WorkerID = 0
                    }
                    return e
                });

                setAzmanotListDefault(defaultWorker)
                setAzmanotList(azmanotList)
                putWorkersAzmanot('delete', setterWorker)
            }
        }
    }
    // 78102
    // temp func until add pickup group
    
    const dispatchCashWorkers = (selectedArray, restArray) => {
        dispatch({type: CHASH_WORKERS_LISTS, payload: [selectedArray, restArray]})
    }
    useEffect(() => {
        dispatchCashWorkers(confermedWorkers, workersList)
    }, [confermedWorkers])
    // filter workers 
    const filterDublicate = (workerList, checkedList) => {
        let arr = []
        for (let i = 0; i < workerList.length; i++) {
            let flag = true
            for (let j = 0; j < checkedList.length; j++) {
                if (workerList[i].WorkerId === checkedList[j].WorkerId) {
                    flag = false
                    break
                }
            }
            if (flag) {
                arr.push(workerList[i])
            }
        }
        return arr
    }

    const filterWorkers = (value) => {
        console.log(123)
        if (!value) {
            const filtered = filterDublicate(workersListDefault, confermedWorkersDefault)
            setWorkersList(filtered)
            setConfermedWorkers(confermedWorkersDefault)
            // dispatchCashWorkers(confermedWorkersDefault, filtered)
        }
        else {
            console.log(confermedWorkersDefault)
            const filtered = workersListDefault.filter(item => item.PickupGroup === +value)
            const filteredConfermed = confermedWorkersDefault.filter(item => item.PickupGroup === +value)
            
            const filteredDublicate = filterDublicate(filtered, filteredConfermed)

            setConfermedWorkers(filteredConfermed)
            setWorkersList(filteredDublicate)
            // dispatchCashWorkers(filteredConfermed, filteredDublicate)

        }
    }
    console.log(confermedWorkers)
    const spinLoader = () => {
        setIsSpin(true);
        setTimeout(() => {
            setIsSpin(false)
        }, 500)
    }
    // console.log(workerGroup)
    useEffect(() => {
        console.log('1')
        if(workerId) {
            onSelectWorker(workerId)
        }
    }, [azmanotListDefault])

    const patchOrderRemark = async (string) => {
        dispatch({type: LOAD })
        try {
            let res = await axios({
                url: PATCH_PALLET,
                method: 'PATCH',
                headers: {
                    'Authorization': 'Bearer ' + getToken
                },
                data: {
                    "collectComm": string,
                    // "distributNote": "distributNote 50 ",
                    "pallets": [],
                    "orders": [selectedOrder.OrderID]
                }
            })
            setSelectedOrder((prev) => {
                return {
                    ...prev,
                    Collect_Comm: string
                }
            })
            const editedOrderDef = azmanotListDefault.map((item) => {
                if(item.OrderID === selectedOrder.OrderID) {
                    item.Collect_Comm = string
                }
                return item
            })
            const editedOrder = azmanotList.map((item) => {
                if(item.OrderID === selectedOrder.OrderID) {
                    item.Collect_Comm = string
                }
                return item
            })
            setAzmanotListDefault(editedOrderDef);
            setAzmanotList(editedOrder);
            setIsOpenRemarkModal(false);
        } catch (err) {
            
        } finally {
            dispatch({type: LOAD_END })

        }
    }
    const [inputWorker, setInputWorker] = useState('')

    const renderWorkerList = useMemo(() => {
        if(!workersList) return []
        const filtered = workersList.filter(e => e.WorkerName?.toLowerCase().includes(inputWorker))
        return filtered
    }, [workersList, inputWorker])

    const PRemarkModal = {
        ...selectedOrder,
        saveFunc: patchOrderRemark,
       
    }
  
    return (
        <SectionMain>
            <SectionMainContainer>
            {
                isOpenRemarkModal &&
                    <ColumModal 
                        title='הערת לקוט'
                        controlFunc={() => {setIsOpenRemarkModal(!isOpenRemarkModal)}}
                        childrenProps={PRemarkModal}
                        Children={LikutRemarkModal}
                    />
            }
                
                <Title label={'ניהול משמרת'} color={'#297f76'} />
                <DatePickerWrapper>
                    <DatePickerModule
                        setSelectedDateStart={setStartDate}
                    />
                </DatePickerWrapper>
                <BodyContainer>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <BodyGroupContainer>
                            <BodyGroupTitleWrapper>
                                <InputWrapper>
                                    <SearchInput 
                                        input={inputWorker}
                                        setInput={setInputWorker}
                                        value={'חיפוש מלקט'}
                                    />
                                </InputWrapper>
                                <IconWrapperOrders onClick={handleModalWorkers}>
                                    {!isOpenWorkerFilter ? <FilterIcon /> : <CloseIcon />}
                                </IconWrapperOrders>
                                <MainModalWrapper primal={isOpenWorkerFilter}>
                                    <FilterContainer>
                                        <WorkersSelectList
                                            workerGroup={workerGroup}
                                            workersListDefault={workersListDefault}
                                            setWorkersList={setWorkersList}
                                            filterWorkers={filterWorkers}

                                        />
                                    </FilterContainer>
                                </MainModalWrapper>

                            </BodyGroupTitleWrapper>
                            <BodyGroupContentWrapper>
                                {confermedWorkers.length > 0 ?
                                    <BodyGroupInnerContainer>

                                        {confermedWorkers.map(item => <LikutWorkerItem
                                        
                                            key={item.WorkerId} {...item}
                                            onSelectWorker={onSelectWorker}
                                            setWorkerId={setWorkerId}
                                            workerId={workerId}
                                            setConfermedWorkers={setConfermedWorkers}
                                            setConfermedWorkersDefault={setConfermedWorkersDefault}
                                            confermedWorkersDefault={confermedWorkersDefault}
                                            confermedWorkers={confermedWorkers}
                                            workersList={workersList}
                                            setWorkersList={setWorkersList}
                                            azmanotListDefault={azmanotListDefault}
                                            currentWorkerAzmanot={currentWorkerAzmanot}
                                            azmanotList={azmanotList}
                                            droppableItem={droppableItem}
                                        />)}
                                    </BodyGroupInnerContainer>
                                    :
                                    ''
                                }
                                <BodyGroupInnerContainer>
                                    ---------
                                    {renderWorkerList.map(item =>
                                        <LikutWorkerItem
                                            {...item}
                                            key={item.WorkerId} 
                                            onSelectWorker={onSelectWorker}
                                            setWorkerId={setWorkerId}
                                            workerId={workerId}
                                            setConfermedWorkers={setConfermedWorkers}
                                            setConfermedWorkersDefault={setConfermedWorkersDefault}
                                            confermedWorkers={confermedWorkers}
                                            workersList={workersList}
                                            setWorkersList={setWorkersList}
                                            confermedWorkersDefault={confermedWorkersDefault}
                                            azmanotListDefault={azmanotListDefault}
                                            currentWorkerAzmanot={currentWorkerAzmanot}
                                            azmanotList={azmanotList}
                                            droppableItem={droppableItem}
                                        />
                                    )}
                                </BodyGroupInnerContainer>
                                {/* {workerGroup.map(elem =>
                                    <BodyGroupInnerContainer>
                                        {elem.Description}
                                        {workersList.map(item => {
                                            if (item.PickupGroup === elem.SecondCode) {
                                                return <LikutWorkerItem
                                                    key={item.WorkerId} {...item}
                                                    onSelectWorker={onSelectWorker}
                                                    setWorkerId={setWorkerId}
                                                    workerId={workerId}
                                                    setConfermedWorkers={setConfermedWorkers}
                                                    confermedWorkers={confermedWorkers}
                                                    workersList={workersList}
                                                    setWorkersList={setWorkersList}
                                                />
                                            } else {return ''}
                                        }
                                        )}
                                    </BodyGroupInnerContainer>
                                )} */}
                            </BodyGroupContentWrapper>
                        </BodyGroupContainer>
                        <BodyGroupContainer style={{ background: 'rgba(122,176,171, 0.6)' }}>
                            <BodyGroupTitleWrapper >
                                הזמנות
                                <IconWrapperOrdersSecond >
                                    {azmanotList?.length}
                                </IconWrapperOrdersSecond>
                                <IconWrapperOrders
                                    primal={isSpin}
                                    style={{left: 'calc(50% - 1.5em)', top: '-2em'}}
                                    onClick={() => {
                                        getAzmanotList(startDate, getAzmanotList)
                                        spinLoader()
                                    }}
                                >
                                    <ReloadIcon />
                                </IconWrapperOrders>
                                <IconWrapperOrders onClick={handleModalOrders}>
                                    {!isOpenOrderFilter ? <FilterIcon /> : <CloseIcon />}
                                </IconWrapperOrders>
                                <ModalFilterAzmanot
                                    deliveryCodes={deliveryCodes}
                                    transportAreas={transportAreas}
                                    isOpenOrderFilter={isOpenOrderFilter}
                                    setIsOpenOrderFilter={setIsOpenOrderFilter}
                                    currentWorkerAzmanot={currentWorkerAzmanot}
                                    azmanotListDefault={azmanotListDefault}
                                    setAzmanotList={setAzmanotList}
                                    workerGroup={workerGroup}
                                    filterWorkers={filterWorkers}
                                    customerGroup={customerGroup}
                                    sellerList={sellerList}
                                    customerList={customerList}
                                    prodTypes={prodTypes}
                                    setFilterFunc={setFilterFunc}
                                    marketAreaList={marketAreaList}
                                />
                            </BodyGroupTitleWrapper>
                            <Droppable droppableId={'droppble1'}>
                                {provided =>
                                    <BodyGroupContentWrapper
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        {memoizedOrders.map((item, index) => 
                                            <OrderGetheringItemWithDetailes 
                                                key={item.OrderID} 
                                                {...item}
                                                Color={customersDict[item.CustomerID].Color}
                                                item={item} 
                                                index={index} 
                                                selectIconForPalletType={selectIconForPalletType} 
                                                openEditRemarkLikut={openEditRemarkLikut}  
                                                workersList={workersHashTable}
                                               
                                            />)}
                                        {provided.placeholder}
                                    </BodyGroupContentWrapper>
                                }

                            </Droppable>
                        </BodyGroupContainer>
                        <BodyGroupContainer>
                            <BodyGroupTitleWrapper>
                                הזמנות למלקט
                                <IconWrapperOrdersSecond>
                                    {currentWorkerAzmanot?.length}
                                </IconWrapperOrdersSecond>
                            </BodyGroupTitleWrapper>
                            <Droppable droppableId={'droppble2'}>
                                {provided =>
                                    <BodyGroupContentWrapper
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        {currentWorkerAzmanot.map((item, index) => 
                                            <OrderGetheringItemWithDetailes 
                                                key={item.OrderID} 
                                                {...item}
                                                item={item} 
                                                index={index} 
                                                selectIconForPalletType={selectIconForPalletType} 
                                                openEditRemarkLikut={openEditRemarkLikut}
                                                workersList={workersHashTable}
                                                
                                            />)}
                                        {provided.placeholder}
                                    </BodyGroupContentWrapper>
                                }
                            </Droppable>
                        </BodyGroupContainer>
                    </DragDropContext>
                </BodyContainer>
            </SectionMainContainer>
        </SectionMain>
    )
}

const InputWrapper = styled.div`
    width: 100%;
    padding: 0 0.2em 0 4em;
`