import React, {useState, useMemo, useEffect, useContext} from 'react'
import OrderlikeLayout from '../../../../../generic/Layouts/OrderlikeLayout'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/reducers/rootReducer'
import useDataFetcher from '../../knisot/hooks/useFetchData'
import { IWarhouseListItem } from '../../../../../modules/API/Defaults/models/warhouseList'
import { Defaults } from '../../../../../modules/API/Defaults/defaults'
import { IPostProductsMlaiRequest, IProductMlaiForCreate } from '../../../../../modules/API/Mlai/models/productsForCreate'
import { Mlai } from '../../../../../modules/API/Mlai/mlai'
import ControlSearchPannelLayout from '../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import DatePickerModule from '../../../../../generic/datePicker/datePicker'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import BodyScrollContainer from '../../../../../generic/Layouts/Wrappers/BodyScrollContainer'
import TitlesList from '../../../../../generic/titlesRowItem/TitlesList'
import ProductItem from './ProductItem'
import { useHistory } from 'react-router-dom'
import { LOAD, LOAD_END } from '../../../../../../store/actions/actionsTypes'
import ControlButton from '../../../../../generic/buttons/ControlButton'
import styled from 'styled-components'
import { LINKS } from '../../../../../Routs/config'
import CircleActiveFilterBtn from '../../../../../generic/particles/CircleActiveFilterBtn'
import DropdownByIcon from '../../azmanotRehesh/Layouts/DropdownByIcon'
import { WarehouseIcon } from '../../../../../generic/icons/Icons'
import WarehouseFilter from './WarehouseFilter'
import { addIDForTheList } from '../../../../../generic/utils/addUIDForTheList'
import { Gathering } from '../../../../../modules/API/Gathering/gathering'
import { ContextModal } from '../../../../../modules/modal/modalContext'
import { handleScan } from '../utils/scanHandler'

type Props = {}

const MlaiCreateMain = (props: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tranzit, rights } = useSelector((state: RootState) => state.mainReducer.user) || {}
  const [modal, setModal] = useContext(ContextModal)
  const [productsList, setProductsList] = useState<IProductMlaiForCreate[]>([])
  const [selectedTranzit, setSelectedTranzit] = useState<IWarhouseListItem | null>(null)
  const isCustomer = rights?.includes('CUSTOMER')

  const [input, setInput] = useState<string>('')
  const [startData, setStartData] = useState<string>('')

  const {
    data: warehouseList
  } = useDataFetcher<IWarhouseListItem[]>(
    Defaults.getWarhouseList, '2'
  )
  
  const getProducts = async (tranzitID: number | string) => {
   
    dispatch({type: LOAD})
    try {
      const res = await Mlai.getProductsForCreate(tranzitID.toString())
      setProductsList(addIDForTheList(res))
    } catch (error) {
      
    } finally {
      dispatch({type: LOAD_END})
    }
  }

  const postProducts = async () => {
    if(!productsList) return
    dispatch({type: LOAD})
    try {
      const dataPost: IPostProductsMlaiRequest = {
        DateStock: startData,
        TranzitID: isCustomer ? Number(tranzit || 0) : Number(selectedTranzit?.WarehouseID || 0),
        Details: productsList.filter(line => line.isEdited && (line?.tempQty || 0) > 0).map(item => {
          let {id, isEdited, tempQty, tempWeight, ...rest} = item
          return {
            ...rest,
            NumPackagesActulal: tempQty || 0,
            WeightActual: tempWeight || 0
          }
        })
      }
      const res = await Mlai.postProducts(dataPost)
      history.push(LINKS.mlai.mlai.mainURL)
    } catch (error) {
      
    } finally {
      dispatch({type: LOAD_END})
    }
  }
  const handleChangeQty = (qty: number, weightPerPack: number, index: number) => {
    if(!productsList) return
    const newList = [...productsList]
    // newList[index].NumPackagesActulal = qty
    // newList[index].WeightActual = qty * weightPerPack
    newList[index].tempQty = qty
    newList[index].tempWeight = qty * weightPerPack
    newList[index].isEdited = true
    console.log(newList)
    setProductsList(newList)
  }

  const handleModal = (mood: boolean, message: string) => {
    setModal({
      open: true,
      value: message,
      mood: mood
    })
    setTimeout(() => {
      setModal({
          ...modal,
          open: false
      })
    }, 3000)
  }

  const handleScanFunc = async () => {
     handleScan({
      selectedTranzit,
      productsList,
      setProductsList,
      input,
      setInput,
      handleModal}
    )
  }
  // const handleScan = async () => {
  //   if (!selectedTranzit) return
  //     // check if input has something expect numbers by regular expression in english and hebrew

  //   if (input.match(/[a-z\u0590-\u05FF]/i)) return;
  //   try {
  //     const res = await Gathering.getKnisaByBarcode(input)
  //     const findInTheList = productsList.find(item => 
  //       item.ProductID === res.entryLine[0].ProductID &&
  //       item.VarietyID === res.entryLine[0].VarietyID
  //     )
  //     if(findInTheList) {
  //       setInput(`${findInTheList.ProductDesc.trim()} ${findInTheList.VarietyDesc.trim()}`)
  //     } else {
  //       const newProduct: IProductMlaiForCreate = {
  //         GeneralProductID: 0,
  //         NumPackagesActulal: res.entryLine[0].BalanceAmount,
  //         ProductDesc: res.entryLine[0].ProductDesc,
  //         ProductID: res.entryLine[0].ProductID,
  //         VarietyDesc: res.entryLine[0].VarietyDesc,
  //         VarietyID: res.entryLine[0].VarietyID,
  //         WeightActual: res.entryLine[0].Gross,
  //         tempQty: 0,
  //         tempWeight: 0,
  //         isEdited: false,
  //         Price: 0,
  //       }
  //       setProductsList([newProduct, ...productsList])
  //       setInput(`${newProduct.ProductDesc.trim()} ${newProduct.VarietyDesc.trim()}`)
  //       setModal({
  //         open: true,
  //         value: `פריט נוסף לרשימה`,
  //         mood: true
  //       })
  //       setTimeout(() => {
  //         setModal({
  //             ...modal,
  //             open: false
  //         })
  //       }, 3000)
  //     }
  //   } catch (error) {
  //     setModal({
  //       open: true,
  //       value: `שגיאה במהלך הסריקה`,
  //       mood: false
  //     })
  //     setTimeout(() => {
  //       setModal({
  //           ...modal,
  //           open: false
  //       })
  //     }, 3000)
  //   }
  // }

  const renderProducts = useMemo(() => {
    if(!productsList) return []
    return productsList.filter(item => 
      `${item.ProductDesc.trim()} ${item.VarietyDesc.trim()}`.includes(input)
    )
  }, [productsList, input])

  const productProps = {
    handleChangeQty: handleChangeQty
  }

  useEffect(() => {
    if(isCustomer ? !tranzit : !selectedTranzit  ) return
    const tranzitID = isCustomer ? (tranzit || 0) : (selectedTranzit?.WarehouseID || 0)
    getProducts(tranzitID)
  }, [tranzit, selectedTranzit])

  return (
    <OrderlikeLayout
      label='ספירה חדשה'
    >
      <ControlSearchPannelLayout>
     
        <DatePickerModule
          setSelectedDateStart={setStartData}
          setSelectedDateEnd={false}
          defEndDate={false}
          selected={false}
          ignorBrod={true}
          disabled={false}
          large
        />
        <SearchInput 
          input={input}
          setInput={setInput}
          enterFunc={handleScanFunc}
        />
        {
          !isCustomer &&
            <WarehouseFilter
                          list={warehouseList || []}
                          selectedWarehouse={selectedTranzit}
                          setSelectedWarehouse={setSelectedTranzit}
                        />
            // <DropdownByIcon
            //   isOreintaionRight
            //           icon={<WarehouseIcon />}
            //           // isSelected={priceFilterMihira !== 0 || priceFilterKniya !== 0}
            //           children={
            //             <WarehouseFilter
            //               list={warehouseList || []}
            //               selectedWarehouse={selectedTranzit}
            //               setSelectedWarehouse={setSelectedTranzit}
            //             />
            //           }
            //       />
        }
      </ControlSearchPannelLayout>
      <BodyScrollContainer>
        <TitlesList 
          list={renderProducts}
          uniqueKey={'RecID'}
          noPadding={false}
          Element={ProductItem}
          additionalData={productProps}
        />
      </BodyScrollContainer>
        <ControlWrapper>
          <ControlButton
            lable={'ביטול'}
            handleClick={() => history.goBack()}
          />
          <ControlButton 
            lable={'שמירה'}
            handleClick={postProducts}
          />
        </ControlWrapper>
      
    </OrderlikeLayout>
  )
}
export default MlaiCreateMain
const ControlWrapper = styled.div`
    width: 100%;
    display: flex;
    gap: 0.5em;
`