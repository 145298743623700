export const titleByCalcMethod = (calcMethod: number) => {
  const baseTitles = [
    { sortingID: 0, titleName: 'פריט' },
    { sortingID: 1, titleName: 'הזמנות לקוח אריזות' },
  ];
  const addictionalPacksTitles = [
    { sortingID: 10, titleName: 'יתרה אריזות' },
    { sortingID: 13, titleName: 'cafui packs' }
  ]
  const stockTitles = [
    { sortingID: 2, titleName: 'מלאי אריזות' },
   
  ];

  const case3Titles = [
    { sortingID: 4, titleName: 'הזמנות לקוח משקל' },
    { sortingID: 5, titleName: 'הזמנות רכש אריזות' },
    { sortingID: 7, titleName: 'הזמנות רכש משקל' },
    ...stockTitles,
    { sortingID: 9, titleName: 'מלאי משקל' },
    { sortingID: 12, titleName: 'יתרה משקל' },
    { sortingID: 15, titleName: 'cafui weight' },
  ];

  const case2Titles = [
    { sortingID: 3, titleName: 'הזמנות לקוח משטחים' },
    { sortingID: 5, titleName: 'הזמנות רכש אריזות' },
    { sortingID: 6, titleName: 'הזמנות רכש משטחים' },
    ...stockTitles,
    { sortingID: 8, titleName: 'מלאי משטחים' },
    { sortingID: 11, titleName: 'יתרה משטחים' },
    { sortingID: 14, titleName: 'cafui pallets' },
  ];

  const defaultTitles = [
    { sortingID: 3, titleName: 'הזמנות לקוח משטחים' },
    { sortingID: 4, titleName: 'הזמנות לקוח משקל' },
    { sortingID: 5, titleName: 'הזמנות רכש אריזות' },
    { sortingID: 6, titleName: 'הזמנות רכש משטחים' },
    { sortingID: 7, titleName: 'הזמנות רכש משקל' },
    { sortingID: 8, titleName: 'מלאי משתחים' },
    { sortingID: 9, titleName: 'מלאי משקל' },
    { sortingID: 10, titleName: 'יתרה אריזות' },
    { sortingID: 11, titleName: 'יתרה משטחים' },
    { sortingID: 12, titleName: 'יתרה משקל' },
    { sortingID: 13, titleName: 'cafui packs' },
    { sortingID: 14, titleName: 'cafui pallets' },
    { sortingID: 15, titleName: 'cafui weights' },
  ];

  switch (calcMethod) {
    case 3:
      return [...baseTitles, ...case3Titles];
    case 2:
      return [...baseTitles, ...case2Titles];
    case 1:
      return [...baseTitles, { sortingID: 5, titleName: 'הזמנות רכש אריזות' }, ...stockTitles, ...addictionalPacksTitles];
    default:
      return [...baseTitles, ...defaultTitles];
  }
};

// export const titleByCalcMethod = (calcMethod: number) => {
//     switch (calcMethod) {
//         case 3:
//           return [
//             'פריט',
//             'הזמנות לקוח אריזות',
//             'הזמנות לקוח משקל',
//             'הזמנות רכש אריזות',
//             'הזמנות רכש משקל',
//             'מלאי אריזות',
//             'מלאי משקל',
//           ]
//         case 2:
//           return [
//             'פריט',
//             'הזמנות לקוח אריזות',
//             'הזמנות לקוח משטחים',
//             'הזמנות רכש אריזות',
//             'הזמנות רכש משטחים',
//             'מלאי אריזות',
//             'מלאי משתחים',
//           ]
//         case 1:
//           return [
//             'פריט',
//             'הזמנות לקוח אריזות',
//             'הזמנות רכש אריזות',
//             'מלאי אריזות',
//           ]
//         default:
//           return [
//             'פריט',
//             'הזמנות לקוח אריזות',
//             'הזמנות לקוח משטחים',
//             'הזמנות לקוח משקל',
//             'הזמנות רכש אריזות',
//             'הזמנות רכש משטחים',
//             'הזמנות רכש משקל',
//             'מלאי אריזות',
//             'מלאי משתחים',
//             'מלאי משקל',
//           ]
//     }
// }

