import React, { useState, useMemo, useContext, useCallback, useEffect } from 'react'
import MarketMainLayout from './Layout/MarketMainLayout'
import styled from 'styled-components'
import TitleLayout from '../../kosherTeudot/generic/TitleLayout'
import AddClientButton from './Components/AddClientButton'
import ColumModal from '../../../../../generic/Layouts/ColumModal'
import AddClientModal from './Components/AddClientModal'
import { IMarketCustomerWithDetails, IMarketPallet, IMarketPalletDetails, IPostCustomer, IPostProduct, IUpdateProduct } from '../../../../../modules/API/Market/models/marketRequests'
import useDataFetcher from '../../knisot/hooks/useFetchData'
import { Market } from '../../../../../modules/API/Market/market'
import BodyLayout from './Layout/BodyLayout'
import BodyDataList from './Components/BodyClients/BodyDataList'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import CustomBlockLoader from '../../knisot/generic/CustomBlockLoader'
import ProductModal from './Components/ProductModal'
import { IGetReuqestResponseNode } from '../../../../../modules/API/Defaults/models/pallets'
import { Pallets } from '../../../../../modules/API/Pallets/pallets'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/reducers/rootReducer'
import AddProductModal from './Components/AddProductModal'
import { Defaults } from '../../../../../modules/API/Defaults/defaults'
import { IProductsWithVarietyItem } from '../../../../../modules/API/Defaults/models/compliteProducts'
import { addIDForTheList } from '../../../../../generic/utils/addUIDForTheList'
import { IGrowerNodeGetResponse } from '../../../../../modules/API/Defaults/models/growers'
import { IClientsItem } from '../../../../../modules/API/Defaults/models/customers'
import { checkPackType } from '../../../../../generic/utils/checkPackType'
import selectBrodMigdal from '../../../../../generic/utils/selectBrodMigdal'
import { Certificates } from '../../../../../modules/API/Certificates/certificates'
import { IPostData } from '../../../../../modules/API/Certificates/models/postData'
import { ContextModal } from '../../../../../modules/modal/modalContext'
import { LOAD, LOAD_END, SELECT_DATA_PRODUCT, SELECT_DATE_FOR_MARKET } from '../../../../../../store/actions/actionsTypes'
import { IProductCashItem } from '../../../../../../store/reducers/market'
import { ISizeItem } from '../../../../../modules/API/Products/models/size'
import { Product } from '../../../../../modules/API/Products/products'
import AddClientModalMemo from './Components/AddClientModal'
import TeudotModalMain from './Components/Teudot/TeudotModalMain'
import CircleActiveFilterBtn from '../../../../../generic/particles/CircleActiveFilterBtn'
import { DollarIcon, InvoiceIcon, ReloadIcon, SettingsIcon } from '../../../../../generic/icons/Icons'
import { IPutItem } from '../../../../../modules/API/Certificates/models/patchItem'
import DatePickerModule from '../../../../../generic/datePicker/datePicker'
import LikutExpressPrintersModal from '../../likutExpress/palletSection/printers/LikutExpressPrintersModal'
import { IPrintersResponseNode } from '../../../../../modules/API/Defaults/models/printers'
import { ISellerResponse } from '../../../../../modules/API/Defaults/models/seller'
import useWebSocket from '../../../../../generic/hooks/useWebSocket'
import DropdownByIcon from '../../azmanotRehesh/Layouts/DropdownByIcon'
import SearchSeller from './Components/SearchSeller'
import { vibrationFunc } from '../../../../../modules/vibrationModule'
import { IWorkerItem } from '../../../../../modules/API/Defaults/models/workers'


export interface IAddCustomer {
  customer: IClientsItem | null;
  ClientID: number,
  CustomerID: number,
  Customer_Name: string,
  Phone: string
}
export interface IReqProduct {
  selectedProduct: IProductsWithVarietyItem | null;
  GrowerID: number;
  PalletNum: number;
  Qty: number;
  Weight: number;
  PalletType: number;
  CustomerID: number;
  PackID: number;
  Price: number;
  Type_SaleCalculation: number;
  Remarks: string;
  EntryNum: string;
  SizeID: string;
  CustomerSeller: string;
}
export interface ISelectPalletProps {
  pallet: number;
  client: number;
  name: string;
  seller: number;
}
const viewWidth = window.innerWidth
console.log(viewWidth)
const MarketCollectMain = () => {
  const dispatch = useDispatch()
  const{defaults, workerList} = useSelector((state: RootState) => state.defaults)

  const sellerID = useSelector((state: RootState) => state.mainReducer.user?.code_internal)
  const rights = useSelector((state: RootState) => state.mainReducer.user?.rights)
  const isSeller = rights?.includes('SELLER')
  const isWorker = rights?.includes('WORKER')
  const isManager = workerList.find((item: IWorkerItem) => item.WorkerId.toString() === (sellerID || 0))?.Role_108 === 5 

  const [_, setModal] = useContext(ContextModal)
  const [isLoadingReq, setIsLoadingReq] = useState<boolean>(false)
  const [date, setDate] = useState<string>('')
  const overweightDelta = selectBrodMigdal(defaults, 629, 'Num_Value');
  const defaultPackage = selectBrodMigdal(defaults, 382, 'Num_Value');
  const clientCash: number = selectBrodMigdal(defaults, 611, "Num_Value")
  const isRequiredSeller = selectBrodMigdal(defaults, 631, "Logical_Value")
  const isDisableToCreateNewCustomer = !selectBrodMigdal(defaults, 635, "Logical_Value")
  const defaultArizaTranzit: number = useSelector((item: RootState) => item.defaults.defaults.filter((e: { Int_ID: number }) => e.Int_ID === 5)[0]?.Num_Value);
  const defaultPalletType: number = useSelector((item: RootState) => item.defaults.defaults === null ? '' : item.defaults.defaults.filter((e: { Int_ID: number, Sub_ID: string }) => {
    return e.Int_ID === 14 && e?.Sub_ID?.trim() === '1'
  })[0]?.Num_Value)
  const packageList = useSelector((state: RootState) => state.defaults.packageList)
  const [iOpenAddClient, setOpenAddClient] = useState<boolean>(false);
  const [isOpenAddProduct, setIsOpenAddProduct] = useState<boolean>(false);
  const [isOpenTeudotModal, setIsOpenTeudotModal] = useState<boolean>(false);
  const [selectedPallet, setSelectedPallet] = useState<ISelectPalletProps | null>(null);
  const [search, setSearch] = useState<string>("");
  const [isOpenProductModal, setIsOpenProductModal] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<IMarketPalletDetails | null>(null);
  const [selectedSeller, setSelectedSeller] = useState<number>(isSeller ? Number(sellerID) : 0)
  const [sizeData, setSizeData] = useState<ISizeItem[]>([])
  const [sellerForEditProduct, setSellerForEditProduct] = useState<number>(0)
  const [dataClients, setDataClients] = useState<IMarketCustomerWithDetails[]>([])
  
  const {
    socket,
    message,
    error,
    sendMessage
  } = useWebSocket('wss://my.agroline.co.il/wspallets/ws')

  const {
    data: dataPallets
  } = useDataFetcher<IGetReuqestResponseNode>(
    Pallets.getPalletList, "data"
  )

  const {
    data: dataProductsWithVariety,
  } = useDataFetcher<IProductsWithVarietyItem[]>(
    Defaults.getProductsWithVariety, "data"
  )

  const dataProductsTypesWithID = useMemo(() => {
    if (!dataProductsWithVariety) return []
    return addIDForTheList(dataProductsWithVariety)
  }, [dataProductsWithVariety])

  const {
    data: dataGrowers
  } = useDataFetcher<IGrowerNodeGetResponse>(
    Defaults.getGrowerList, "data"
  )

  const {
    data: dataSeller
  } = useDataFetcher<ISellerResponse>(
    Defaults.getSellerList, "data"
  )

  const hardReload = () => {
    window.location.reload()
  }

  const errorHandling = (err: any) => {
    setModal({
      open: true,
      value: err?.response?.data?.message || "משהו השתבש",
      mood: false
    })
    setTimeout(() => {
      setModal({
        open: false,
        value: '2'
      })
    }, 3000)
  }

  const getSizes = async (productID: number) => {
    try {
      let res: ISizeItem[] = await Product.getSizesByProduct(productID);
      setSizeData(res)
    } catch (err) { }
  }

  const cashProduct = (data: IProductCashItem | null) => {
    let cashData = {
      customerID: selectedPallet?.client || 0,
      data: data
    }
    console.log(cashData)
    dispatch({ type: SELECT_DATA_PRODUCT, payload: cashData })
  }

  /**
   * The `addCustomer` function is used to add a new customer to a list of existing customers, either
   * by providing the customer object directly or by making an API call to create a new customer.
   * @param {IAddCustomer}  - - `customer`: An object containing customer details such as `ClientID`,
   * `CustomerID`, `Customer_Name`, `Phone`, etc.
   * @returns The function does not explicitly return anything.
   */

  const addCustomer = async ({
    customer, ClientID, CustomerID, Customer_Name, Phone
  }: IAddCustomer) => {
    const newArr = dataClients ? [...dataClients] : []
    const sellerDesc = dataSeller?.sellers?.find(item => item.SellerID === selectedSeller)?.Seller_Desc.trim() || ''
    if (customer) {
      const data = {
        ClientID: customer.ClientID,
        CustomerID: customer.CustomerID,
        Customer_Name: customer.Customer_Name,
        DistributNote: customer.DistributNote,
        Marketing_AreaID: customer.Marketing_AreaID,
        Pallets: [],
        Phone: customer.Phone,
        ShippAddress: customer.ShippAddress,
        CustomerSeller: `${customer.CustomerID}-${selectedSeller}`,
        SellerID: selectedSeller,
        Seller_Desc: sellerDesc
      }
      if (dataClients?.some(item => item.CustomerSeller === `${customer.CustomerID}-${selectedSeller}`)) {
        setModal({
          open: true,
          value: "לקוח כבר קיים",
          mood: false
        })
        setTimeout(() => {
          setModal({
            open: false,
            value: '2'
          })
        }, 3000)
        return

      }
      newArr.unshift(data)
      setDataClients(newArr)
      sendMessage(JSON.stringify(data))
      handleTitleClick()
      return
    }
    try {
      // if(!dataClients) return
      try {

        const formatedPhone = Phone.split('').filter(item => {
          return /[0-9]/.test(item)
        }).join('')

        let reqData: IPostCustomer = {
          ClientID: ClientID,
          CustomerID: CustomerID,
          Customer_Name: Customer_Name,
          Phone: formatedPhone,
        }

        let res = await Market.createCustomer(reqData)

        newArr.unshift({
          ...res,
          CustomerSeller: `${res.CustomerID}-${selectedSeller}`,
          Pallets: [],
          SellerID: selectedSeller,
          Seller_Desc: sellerDesc

        })
        setDataClients(newArr)
        sendMessage(JSON.stringify({
          ...res,
          CustomerSeller: `${res.CustomerID}-${selectedSeller}`,
          Pallets: [],
          SellerID: selectedSeller,
          Seller_Desc: sellerDesc

        }))

      } catch (err) { }


      handleTitleClick()
    } catch (error) { }
  }

  const deleteProduct = async (
    pallet: number,
    customerSellerID: string,
    recID: number,
  ) => {
    if (!dataClients) return
    try {
      let res = await Pallets.deleteProductFromPallet(recID)

      const newArr = [...dataClients]
      const custIndex = hashCustomersList[customerSellerID].index
      const palletIndex = hashCustomersList[customerSellerID].palletHash[pallet]?.index
      const found = newArr[custIndex].Pallets[palletIndex]

      if (!found) return

      const newPallets = found.Details.filter(item => item.RecID !== recID)
      newArr[custIndex].Pallets[palletIndex].Details = newPallets
      setDataClients(newArr)
      sendMessage(JSON.stringify(newArr[custIndex]))
      // sendMessage(JSON.stringify([newArr[custIndex]]))
      setModal({
        open: true,
        value: "הפרית נמחק בהצלחה",
        mood: true
      });
      setTimeout(() => {
        setModal({
          open: false,
          value: '2'
        });
      }, 3000);

    } catch (error: any) {
      errorHandling(error)
    } finally {

    }
  }
  const deletePallet = async (pallet: number, client: string) => {
    if (!dataClients) return
    try {

      await Pallets.deletePalletGo(pallet)
      const newArr = [...dataClients]
      const custIndex = hashCustomersList[client].index
      const palletIndex = hashCustomersList[client].palletHash[pallet]?.index
      newArr[custIndex].Pallets.splice(palletIndex, 1)
      setDataClients(newArr)
      sendMessage(JSON.stringify(newArr[custIndex]))

    } catch (err: any) {
      errorHandling(err)
    }
  }

  /**
   * The function `createTeuda` is an asynchronous function that creates a certificate for a market
   * customer with details, updates the dataClients array, and displays a success or error message in a
   * modal.
   * @param {IMarketCustomerWithDetails} item - IMarketCustomerWithDetails - an interface representing
   * a market customer with additional details
   * @returns The function `createTeuda` does not have a return statement, so it does not explicitly
   * return anything.
   */
  const createTeuda = async (item: IMarketCustomerWithDetails) => {
    if (!dataClients || isLoadingReq) return

    dispatch({ type: LOAD })
    try {
      const postData: IPostData = {
        CustomerID: item.CustomerID,
        DeliveryDate: date,
        DeliveryTime: "",
        OrderNum: 0,
        PalletNums: item.Pallets.map(item => item.Pallet_Num),
        Tranzit: 0,
        Vehicle_Code: 0,
        SellerID: item.SellerID || Number(item.CustomerSeller.split('-')[1])
      }
      let res = await Certificates.createCert(postData)

      const newArr = [...dataClients];
      let cutItem = newArr.splice(hashCustomersList[item.CustomerSeller].index, 1)
      const resSocket = await Market.deleteCustomer(item.CustomerSeller)
      console.log(cutItem)

      setDataClients(newArr)
      setModal({
        open: true,
        value: "משלוח נוסף בהצלחה",
        mood: true
      });
      setTimeout(() => {
        setModal({
          open: false,
          value: '2'
        });
      }, 3000);
    } catch (error: any) {
      errorHandling(error)
    } finally {
      dispatch({ type: LOAD_END })
    }
  }

  const getCustomerItemByDelivery = async (deliveryNum: number, deliverySub: number) => {
    try {
      const res: IMarketCustomerWithDetails[] = await Market.getItemByDelivery(deliveryNum, deliverySub)

      const isExist = dataClients?.some(item =>
        // item.CustomerID === res[0].CustomerID &&
        item.CustomerSeller === res[0].CustomerSeller
      )
      if (isExist) {
        setModal({
          open: true,
          value: "לקוח כבר קיים",
          mood: false
        });
        setTimeout(() => {
          setModal({
            open: false,
            value: '2'
          });
        }, 3000);
        return
      }
      const formRes: IMarketCustomerWithDetails = {
        ...res[0],
        Delivery_Num: deliveryNum,
        Delivery_Sub: deliverySub,
        Pallets: res[0].Pallets.map(item => {
          return {
            ...item,
            isTeudaExist: true,
          }
        })
      }
      setDataClients([formRes, ...dataClients || []])
      setIsOpenTeudotModal(false)
    } catch (err) { 
      errorHandling(err)
    }
  }

  const updateTeuda = async (item: IMarketCustomerWithDetails, isDisableClose?: boolean) => {
    if (!item.Delivery_Num) return
    dispatch({ type: LOAD })


    try {
      const newArr = (
        !isDisableClose ?
          [...dataClients || []].filter(client => client.CustomerID !== item.CustomerID)
          :
          [...dataClients || []]
      ).map(client => {
        if (client.CustomerID === item.CustomerID) {
          return {
            ...client,
            Pallets: client.Pallets.map(pallet => {
              return {
                ...pallet,
                isTeudaExist: true
              }
            })
          }
        }
        return client
      })
      if (item.Pallets.every(item => item.isTeudaExist)) {
        setDataClients([...newArr])
        return
      }
      const putData: IPutItem = {
        PalletNums: item.Pallets.filter(item => !item.isTeudaExist).map(item => item.Pallet_Num),
        OrderNum: 0,
        DeliveryNum: item.Delivery_Num,
        DeliverySub: item.Delivery_Sub || 0
      }

      await Certificates.updateDeliveryByOrderOrPallet(putData)


      setDataClients([...newArr])

      setModal({
        open: true,
        value: "משלוח עודכן בהצלחה",
        mood: true
      });
      setTimeout(() => {
        setModal({
          open: false,
          value: '2'
        });
      }, 3000);

    } catch (error) {
      errorHandling(error)
    } finally {
      dispatch({ type: LOAD_END })
    }
  }

  /* The above code is using the `useMemo` hook in React to create a memoized version of the
  `hashCustomersList` function. This function takes an array of `dataClients` and returns a hash map
  of customers with their corresponding index and a hash map of pallets with their corresponding
  index. */

  const hashCustomersList = useMemo(() => {
    if (!dataClients) return {}
    return dataClients.reduce((acc: Record<string, { index: number, palletHash: Record<number, { index: number }> }>, curr: IMarketCustomerWithDetails, index) => {
      const pallets = curr.Pallets.reduce((accP: Record<number, { index: number }>, curP, indexP) => {
        accP[curP.Pallet_Num] = {
          index: indexP
        }
        return accP
      }, {})
      acc[curr.CustomerSeller] = {
        index: index,
        palletHash: pallets
      }
      return acc
    }, {})
  }, [dataClients])


  /**
   * The `createProduct` function is an asynchronous function that takes in various parameters related
   * to a product and dispatches actions to load data, saves the product data, and updates the state
   * with the new product information.
   * @param {IReqProduct}  - - `selectedProduct`: The selected product object.
   * @returns The function `createProduct` is not returning anything.
   */
  const createProduct = async ({
    selectedProduct,
    GrowerID,
    PalletNum,
    Qty,
    Weight,
    PalletType,
    CustomerID,
    PackID,
    Price,
    Type_SaleCalculation,
    Remarks,
    EntryNum,
    SizeID,
    CustomerSeller
  }: IReqProduct) => {
    dispatch({ type: LOAD })
    try {

      if (!selectedProduct) return
      const packType = checkPackType(packageList, PackID)
      let reqData: IPostProduct = {
        QualityID: 0,
        WarehouseID: defaultArizaTranzit || 0,
        GrowerID: GrowerID,
        ProductID: selectedProduct?.ProductID,
        VarietyID: selectedProduct.VarietyID,
        PalletNum: PalletNum,
        OrderNum: 0,
        Qty: packType === '1' ? Weight : Qty,
        Weight: packType === '2' ? Qty : Weight,
        PalletType: PalletType || defaultPalletType || 0,
        OrderRowRecID: 0,
        CustomerID: CustomerID,
        ScanProblem: 0,
        ChangeReason: 0,
        PackID: PackID,
        Reseller_Code: Number(CustomerSeller.split('-')[1]),
        SalePrice: Price,
        Type_SaleCalculation: Type_SaleCalculation,
        Type_BuyCalculation: Type_SaleCalculation,
        Remarks: Remarks,
        EntryNum: EntryNum,
        SizeID: SizeID
      }

      let res: {
        palletNum: number,
        message: string,
        lines18: IMarketPalletDetails[]
      } = await Market.saveProduct(reqData)

      if (!dataClients) return
      const newArr = [...dataClients]
      const custIndex = hashCustomersList[CustomerSeller].index
      const palletIndex = hashCustomersList[CustomerSeller].palletHash[res.palletNum]?.index
      const found = newArr[custIndex].Pallets[palletIndex]

      if (!found) {
        const pallet = {
          CustomerID: CustomerID,
          Customer_Name: "",
          Details: res.lines18,
          Pallet_Date: "",
          Pallet_Num: res.palletNum,
          Reseller_Code: +CustomerSeller.split('-')[1],
          Status: 0,
        }
        newArr[custIndex].Pallets.push(pallet)

      } else {
        newArr[custIndex].Pallets[palletIndex].Details = [...res.lines18]
      }

      setDataClients(newArr)
      if (!newArr[custIndex].Delivery_Num) {
        sendMessage(JSON.stringify(newArr[custIndex]))
      } else {
        await updateTeuda(newArr[custIndex], true)
      }
      handleOpenAddProduct()

    } catch (error: any) {
      errorHandling(error)
    } finally {
      dispatch({ type: LOAD_END })
    }
  }

  /**
   * The `updateProduct` function updates a product in the dataClients array with the provided data,
   * and displays a success or error message in a modal.
   * @param {IUpdateProduct} data - The `data` parameter is of type `IUpdateProduct`, which is an
   * interface representing the data needed to update a product.
   * @returns The function `updateProduct` returns a Promise.
   */

  const updateProduct = async (data: IUpdateProduct) => {
    if (!dataClients || !selectedProduct || !selectedProduct.CustomerID) return
    dispatch({ type: LOAD })
    try {
      const customerSellerID = `${selectedProduct.CustomerID}-${sellerForEditProduct}`
      const palletNum = selectedProduct.Pallet_Num
      const recID = selectedProduct.RecID
      const res: IMarketPalletDetails = await Market.updateProduct(recID, data)
      const newArr = [...dataClients]
      const custIndex = hashCustomersList[customerSellerID].index
      const palletIndex = hashCustomersList[customerSellerID].palletHash[palletNum]?.index
      const found = newArr[custIndex].Pallets[palletIndex]
      if (!found) return

      const newPallets = found.Details.map(item => {
        if (item.RecID === recID) {
          item = res
        }
        return item
      })
      newArr[custIndex].Pallets[palletIndex].Details = newPallets
      setDataClients(newArr)
      sendMessage(JSON.stringify(newArr[custIndex]))
      setModal({
        open: true,
        value: "הפריט עודכן בהצלחה",
        mood: true
      });
      setTimeout(() => {
        setModal({
          open: false,
          value: '2'
        });
      }, 3000);
      handleOpenProductModal()
    } catch (err: any) {
      errorHandling(err)
    } finally {
      dispatch({ type: LOAD_END })

    }
  }

  const [printersModal, setPrintersModal] = useState<boolean>(false)
  const [printerFormat, setPrinterFormat] = useState<number>(0)
  const [printerNum, setPrinterNum] = useState<number>(0)

  const handlePrintersModal = () => {
    setPrintersModal(!printersModal)
  }

  const {
    data: printers
  } = useDataFetcher<IPrintersResponseNode>(
    Defaults.getPrinters, 'asd'
  )

  const saveToLocalPrinter = (num: string | number) => {
    setPrinterNum(Number(num));
    localStorage.setItem('printerNum', num.toString());
  }

  const loadFromLocalPrinter = () => {
    let pNum = localStorage.getItem('printerNum');
    if (pNum) {
      setPrinterNum(+pNum)
    }
  }

  const saveToLocalPrintFormat = (num: string) => {
    setPrinterFormat(+num);
    localStorage.setItem('printerFormat', num);
  }

  const loadFromLocalPrintFormat = () => {
    let pNum = localStorage.getItem('printerFormat');
    if (pNum) {
      setPrinterFormat(+pNum)
    }
  }

  const printPallet = async (pallet_num: number) => {
    if (!printerNum) {
      setPrintersModal(true)
      return
    }

    dispatch({ type: LOAD });
    try {
      const data = {
        "ZPL_15_75": 3,
        "PalletEntryNum": pallet_num,
        "PalletZPLFormat": printerFormat
      }
      let res = await Pallets.printPalletZPL(printerNum, data)

      if (typeof res !== 'number') {
        let modifyed = res.split('.il').join('.il:9086');
        window.open(modifyed);
      } else {

      }
      dispatch({ type: LOAD_END });
    } catch (err: any) {
      if (err?.response?.status === 404) {
        setModal({
          open: true,
          value: 'שגיאה הדפסת משטח',
          mood: false
        })
        setTimeout(() => {
          setModal({
            open: false
          })
        }, 3000)
      }
      else {
        setModal({
          open: true,
          value: err?.response?.data?.message,
          mood: false
        })
        setTimeout(() => {
          setModal({
            open: false
          })
        }, 3000)
      }
      dispatch({ type: LOAD_END });
    }
  }

  const removeCustomerFromTheList = async (id: string) => {
    if (!dataClients) return
    try {
      const found = dataClients.find((item) => item.CustomerSeller === id)
      if (!found) return
      let res = await Market.deleteCustomer(found.CustomerSeller)
      const newArr = dataClients.filter((item) => item.CustomerSeller !== id)
      setDataClients(newArr)
    } catch (err) {

    }
  }

  const handleOrderStatus = async (item: IMarketCustomerWithDetails, status: number) => {
    try {
       await Market.patchStatusPallet(status, item.CustomerSeller)
    } catch (err: any) {
      setModal({
        open: true,
        value: err?.response?.data?.message || "משהו השתבש",
        mood: false
      })
      setTimeout(() => {
        setModal({
          open: false,
          value: '2'
        })
      }, 3000)
    } finally { }
  }

  const handleTitleClick = useCallback(() => {
    setOpenAddClient(!iOpenAddClient)
  }, [iOpenAddClient])

  const render = useMemo(() => {
    if (!dataClients) return []
    return dataClients.filter((item: IMarketCustomerWithDetails) =>
      item.Customer_Name?.trim().toLowerCase().includes(search) &&
      (selectedSeller !== 0 ? item.SellerID === selectedSeller : true)
    )
  }, [dataClients, search, selectedSeller])

  const handleOpenProductModal = () => {
    setIsOpenProductModal(!isOpenProductModal)
  }
  const handleOpenTeudotModal = () => {
    setIsOpenTeudotModal(!isOpenTeudotModal)
  }
  const handleSelectProduct = (data: IMarketPalletDetails, sellerID: number) => {
    setSelectedProduct(data)
    setSellerForEditProduct(sellerID)
    handleOpenProductModal()
  }

  const handleOpenAddProduct = () => {
    setIsOpenAddProduct(!isOpenAddProduct)
  }

  const handleSelectForPallet = (pallet: number, client: number, name: string, seller: number) => {
    setSelectedPallet({
      pallet,
      client,
      name,
      seller
    })
    handleOpenAddProduct()
  }

  const teudotModalProps = {
    fetchItem: getCustomerItemByDelivery,
    date: date,
    sellerList: dataSeller?.sellers || [],
    selectedSeller: selectedSeller,
    setSelectedSeller: setSelectedSeller
  }

  const selectClientModalProps = {
    handleSave: addCustomer,
    hashCustomersList: hashCustomersList,
    sellerList: dataSeller?.sellers || [],
    selectedSeller: selectedSeller,
    setSelectedSeller: setSelectedSeller,
    isRequiredSeller: isRequiredSeller,
    isDisableNewCustomer: isDisableToCreateNewCustomer,
    disableAddNewCustomer: !isManager && isWorker
  }

  const selectedProductModalProps = {
    data: selectedProduct,
    updateProduct: updateProduct,
    pallets: dataPallets?.pallets || [],
    growers: dataGrowers?.growers || [],
    isWorker: isWorker
  }

  const addProductModalProps = {
    data: selectedPallet,
    dataGrowers: dataGrowers,
    dataPallets: dataPallets,
    packageList: packageList,
    defaultPallet: defaultPalletType,
    defaultPackage: defaultPackage,
    createProduct: createProduct,
    cashProduct: cashProduct,
    dataProductsWithVariety: dataProductsTypesWithID,
    getSizes: getSizes,
    sizeData: sizeData,
    isWorker: isWorker,
    overweightDelta: overweightDelta
  }

  const bodyDataProps = {
    selectProduct: handleSelectProduct,
    handleSelectForPallet: handleSelectForPallet,
    createTeuda: createTeuda,
    deleteProduct: deleteProduct,
    updateTeuda: updateTeuda,
    removeCustomerFromTheList: removeCustomerFromTheList,
    deletePallet: deletePallet,
    printPallet: printPallet,
    clientCash: clientCash,
    isWorker: isWorker,
    setSelectedSeller: setSelectedSeller,
    handleOrderStatus: handleOrderStatus
  }

  const setDefaultDate = useSelector(
    (state: RootState) => state.marketReucer.date
  );

  const handleDate = (e: object) => {
    dispatch({ type: SELECT_DATE_FOR_MARKET, payload: e });
  };

  const titleForAddProduct = selectedPallet?.pallet === 0 ? 'חדש' : selectedPallet?.pallet

  useEffect(() => {
    loadFromLocalPrinter();
    loadFromLocalPrintFormat();
  }, [])

  useEffect(() => {
    const messageData = JSON.parse(message)
    if (!messageData) return
    let newArr = [...dataClients]
    if (messageData.ID) {
      newArr = [...dataClients].filter(item => item.CustomerSeller !== messageData.ID)
      setDataClients(newArr)
      return
    } else if (Array.isArray(messageData)) {
      setDataClients(messageData)
    } else {
      const index = hashCustomersList[messageData.CustomerSeller]?.index
      if (index !== undefined) {
        newArr[index] = messageData
        setDataClients(newArr)
        const dataCurrent = dataClients[index].Pallets.some(pallet => pallet.Status === 0)
        const dataMessage = messageData.Pallets.some((pallet: IMarketPallet) => pallet.Status === 1)
        if (dataCurrent && dataMessage) {
          vibrationFunc('warning')
          setModal({
            open: true,
            value: `הזמנת הלקוח ${dataClients[index]?.Customer_Name?.trim()} נאספה`,
            mood: true
          })
          setTimeout(() => {
            setModal({
              open: false,
              value: '2'
            })
          }, 6000)
        }
      } else {
        setDataClients([...dataClients, messageData])
      }
    }
  }, [message, socket, error])

  return (
    <MarketMainLayout title='מכירה במקום'>
      {
        printersModal ?
          <LikutExpressPrintersModal
            setPrintersModal={setPrintersModal}
            printersList={printers?.printers || []}
            saveToLocalPrinter={saveToLocalPrinter}
            printerNum={printerNum}
            printPallet={printPallet}
            saveToLocalPrintFormat={saveToLocalPrintFormat}
            printerFormat={printerFormat}
          />
          :
          ''
      }
      {
        isOpenTeudotModal &&
        <ColumModal
          title='תעודות משלוח'
          Children={TeudotModalMain}
          controlFunc={handleOpenTeudotModal}
          childrenProps={teudotModalProps}
          size='md'
        />
      }
      {
        iOpenAddClient &&
        <ColumModal
          title='הוספת לקוח'
          Children={AddClientModalMemo}
          controlFunc={handleTitleClick}
          childrenProps={selectClientModalProps}
        />
      }
      {
        isOpenProductModal &&
        <ColumModal
          title={`${selectedProduct?.ProductDesc.trim()} ${selectedProduct?.VarietyDesc.trim()} ${selectedProduct?.SizeID.trim()}`}
          Children={ProductModal}
          controlFunc={handleOpenProductModal}
          childrenProps={selectedProductModalProps}
        />
      }
      {
        isOpenAddProduct &&
        <ColumModal
          title={`${titleForAddProduct} :משטח ${selectedPallet?.name}`}
          Children={AddProductModal}
          controlFunc={handleOpenAddProduct}
          childrenProps={addProductModalProps}
        />
      }
      {
        viewWidth > 450 ?
          <TitleLayout>
            <TitleWrapper>
              <AddClientButton
                onClick={handleTitleClick}
                hight={"100%"}
              />
             
              <CircleActiveFilterBtn
                func={hardReload}
              >
                <ReloadIcon />
              </CircleActiveFilterBtn>
              <CircleActiveFilterBtn
                func={handlePrintersModal}
              >
                <SettingsIcon />
              </CircleActiveFilterBtn>
              <CircleActiveFilterBtn
                func={handleOpenTeudotModal}
              >
                <InvoiceIcon />
              </CircleActiveFilterBtn>
              <DropdownByIcon
                icon={<DollarIcon />}
                isSelected={!!selectedSeller}
              >
                <SellerWrapper>
                  <SearchSeller
                    defaultID={selectedSeller}
                    setSellerID={setSelectedSeller}
                    list={dataSeller?.sellers || []}
                  />
                </SellerWrapper>
              </DropdownByIcon>
              <TitleFlexContainer >
                <SearchInput
                  input={search}
                  setInput={setSearch}
                  size={'full'}
                />
              </TitleFlexContainer>
              <DatePickerModule
                saveWithoutFormat={handleDate}
                ignorBrod
                multyDate={false}
                setSelectedDateStart={setDate}
                selected={setDefaultDate ? new Date(setDefaultDate) : false}
                isNeedWithoutFormated
                defEndDate={false}
                large
                disabled={false}
                setSelectedDateEnd={false}
              />
            </TitleWrapper>
          </TitleLayout>
          :
          <TitleLayout>
            <TitleWrapper>
              <DatePickerModule
                saveWithoutFormat={handleDate}
                ignorBrod
                multyDate={false}
                setSelectedDateStart={setDate}
                selected={setDefaultDate ? new Date(setDefaultDate) : false}
                isNeedWithoutFormated
                defEndDate={false}
                large
                disabled={false}
                setSelectedDateEnd={false}
              />
              <TitleFlexContainer >
                <SearchInput
                  input={search}
                  setInput={setSearch}
                  size={'full'}
                />
              </TitleFlexContainer>
            </TitleWrapper>
            <TitleWrapper>
              <AddClientButton
                onClick={handleTitleClick}
                hight={"100%"}
              />
              <CircleActiveFilterBtn
                func={handleOpenTeudotModal}
              >
                <InvoiceIcon />
              </CircleActiveFilterBtn>
              <CircleActiveFilterBtn
                func={handlePrintersModal}
              >
                <SettingsIcon />
              </CircleActiveFilterBtn>
              <CircleActiveFilterBtn
                func={hardReload}
              >
                <ReloadIcon />
              </CircleActiveFilterBtn>
              <DropdownByIcon
                icon={<DollarIcon />}
                isSelected={!!selectedSeller}
              >
                <SellerWrapper>
                  <SearchSeller
                    defaultID={selectedSeller}
                    setSellerID={setSelectedSeller}
                    list={dataSeller?.sellers || []}
                  />
                </SellerWrapper>
              </DropdownByIcon>
            </TitleWrapper>
          </TitleLayout>
      }
      <BodyLayout>
        {
          false ?
            <CustomBlockLoader />
            :
            <BodyDataList
              list={render}
              additionalProps={bodyDataProps}
            />
        }
      </BodyLayout>
    </MarketMainLayout>
  )
}
const TitleWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5em;
  flex-direction: row-reverse;
`
const TitleFlexContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  /* padding: 0 1em 0 0; */
  justify-content: center;
`
const SellerWrapper = styled.div`
  width: 200px;
  padding: 0.5em;
  background-color: #fff;
  border-radius: 30px;
`

export default MarketCollectMain