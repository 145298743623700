import React, {useContext, useState} from 'react'
import OrderlikeLayout from '../../../../../generic/Layouts/OrderlikeLayout'
import styled from 'styled-components'
import { Flowers } from '../../../../../modules/API/Flowers/flowers'
import { useHistory, useParams } from 'react-router-dom'
import { InnerPalletItem } from '../../../../../modules/API/Flowers/models/innerPallet'
import ControlButton from '../../../../../generic/buttons/ControlButton'
import { useTranslation } from 'react-i18next'
import { isAvailbleForceSave } from './helpers/messages'
import ColumModal from '../../../../../generic/Layouts/ColumModal'
import ConfirmModal from './Components/ConfirmModal'
import { ContextModal } from '../../../../../modules/modal/modalContext'
import { useDispatch } from 'react-redux'
import { LOAD, LOAD_END } from '../../../../../../store/actions/actionsTypes'
import { LINKS } from '../../../../../Routs/config'

import useDataFetcher from '../../knisot/hooks/useFetchData'
import NewPackageForm from './Components/NewPackageForm'
import PackageList from './Components/PackageList'
import { ContextPrahim } from './helpers/ContextPrahim'
import InnerTitle from './Components/InnerTitle'

const tabList = ['במשטח', 'אריזה חדשה']

const LikutMain = () => {
  const [t] = useTranslation('buttons')
  const {id} = useParams<{id: string}>()
  const history = useHistory()
  const dispatch = useDispatch()
  const {dataTitle, handleDataTitle} = useContext(ContextPrahim)
  const [modal, setModal] = useContext(ContextModal)
  const [barcode, setBarcode] = useState<string>('')
  const [packageData, setPackageData] = useState<InnerPalletItem | null>(null)
  const [disableSave, setDisableSave] = useState<boolean>(false)
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [selectedTab, setSelectedTab] = useState<string>('אריזה חדשה')
  
  const {
    data: palletData,
    setData: setPalletData
  } = useDataFetcher<InnerPalletItem[]>(
    Flowers.getPalletDetailsByID, id === '0' ? '' : id
  )

  const handleScan = async () => {
    dispatch({type: LOAD})
    try {
      const res = await Flowers.getPackageInfo(barcode, +id)
      setPackageData(res)
      if(isAvailbleForceSave[res.Message]) {
        setIsOpenModal(true)
        setDisableSave(false)
      } else if (res.Message === "") {
        handleSave(res)
        setDisableSave(false)
      } else {
        setDisableSave(true)
      }
    } catch (err) {
      setModal({
        open: true,
        value: `משהו השתבש`,
        mood: false
      })
      setTimeout(() => {
        setModal({
          open: false,
          value: '2'
        })
      }
      , 3000)
    } finally {
      dispatch({type: LOAD_END})
    }
  }

  const handleSave = async (
    packageData: InnerPalletItem | null
  ) => {
    if(!packageData) return
    // if(!isConfirmed) {
    //   console.log('not confirmed')
    //   handleOpenModal()
    //   return
    // }
    try {
      dispatch({type: LOAD})
      const postData = {
        Carton_Num: packageData.Pallet_Num,
        Pallet_Num: +id
      }
      const res = await Flowers.postPacakgeToPallet(postData)
      // history.goBack()
      if(id === '0') {
        handleDataTitle({
          customer: res.Customer_Name.trim(),
          method: res.Trasportation_Method
        })
        history.push(LINKS.mishtahim.likutPrahim.editByID + res.Parent_Pallet)
        history.push(LINKS.mishtahim.likutPrahim.editByID + res.Parent_Pallet)
      } else {
        const newList = [res, ...palletData || []]
        setPalletData(newList)
      }
      // setPackageData(null)
      setBarcode('')
      
      setModal({
        open: true,
        value: `נשמר בהצלחה`,
        mood: true
      })
      setTimeout(() => {
        setModal({
          open: false,
          value: '2'
        })
      }, 3000)
    } catch (err) {
      setModal({
        open: true,
        value: `משהו השתבש`,
        mood: false
      })
      setTimeout(() => {
        setModal({
          open: false,
          value: '2'
        })
      }
      , 3000)
    } finally {
      dispatch({type: LOAD_END})
      
    }
  }

  const handleBack = () => {
    history.push(LINKS.mishtahim.likutPrahim.mainURL)
  }

  const handleConfirmation = (action: boolean) => {
    if(action) {
      handleSave(packageData)
    }
    setIsOpenModal(false)
  }

  const handleCloseModal = () => {
    setIsOpenModal(false)
  }


  const handleTabClick = (tab: string) => {
    setSelectedTab(tab)
  }

  const handleDeleteItem = async (item: InnerPalletItem) => {
    if(!palletData) return
    try {
      await Flowers.unAcceptPackage({Carton_Num: item.Pallet_Num})
      const newList = palletData.filter(pallet => pallet.Pallet_Num !== item.Pallet_Num)
      setPalletData(newList)
      setModal({
        open: true,
        value: `נמחק בהצלחה`,
        mood: true
      })
      setTimeout(() => {
        setModal({
          open: false,
          value: '2'
        })
      }, 3000)
      if(newList.length === 0) {
        setSelectedTab('אריזה חדשה')
      }
    } catch (err) {
      setModal({
        open: true,
        value: `משהו השתבש`,
        mood: false
      })
      setTimeout(() => {
        setModal({
          open: false,
          value: '2'
        })
      }, 3000)
    } finally {

    }
  }

  const modalProps = {
    handleConfirmation: handleConfirmation,
    message: packageData?.Message || ''
  }
  
  return (
    <OrderlikeLayout
      label={`משטח: ${id === '0' ? 'חדש' : id}`}
    >
      {
        dataTitle?.customer ?
        <InnerTitle 
          {...dataTitle}   
        /> : ''

      }
      <Container>
        <TabContainer>
          {tabList.map((tab) => (
            <Tab
              key={tab}
              active={selectedTab === tab}
              onClick={() => handleTabClick(tab)}
            >
              {tab}
            </Tab>
          ))}
        </TabContainer>
        {
          isOpenModal &&
          <ColumModal 
            title='האם להמשיך?'
            Children={ConfirmModal}
            childrenProps={modalProps}
            controlFunc={handleCloseModal}
          
          />
        }
        {
          selectedTab === 'במשטח' ?
          <PackageList 
            handleClick={handleDeleteItem}
            palletData={palletData}
          />
          :
          <NewPackageForm 
            packageData={packageData}
            barcode={barcode}
            setBarcode={setBarcode}
            handleScan={handleScan}
          />
        }
      </Container>
      <PseudoWrapper>
        <ControlButton 
          lable={t('exit')}
          handleClick={handleBack}
        />
        {/* <ControlButton 
          lable={t('save')}
          handleClick={handleSave}
          disabled={
            !packageData || 
            disableSave
          }
        /> */}
      </PseudoWrapper>
    </OrderlikeLayout>
  )
}



const PseudoWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5em;
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 30px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

const TabContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  background: #eff3f4;
  z-index: 1;
`

const Tab = styled.div<{ active: boolean }>`
  padding: 8px 16px;
  color: #838484;
  width: 7em;
  border-radius: 30px 30px 0 0;
  cursor: pointer;
  ${props => 
      props.active ? 
      `
        background: #fff;
        border-bottom: none;
        color: #7ab0ab;
      `
      :
      ``
    }
  `


export default LikutMain