import React from 'react'
import styled from 'styled-components'
import selectOnFocus from '../../modules/autoselect'
import PropTypes from 'prop-types'

const CircleQtyInputWrapper = styled.div`
    min-width: ${props => props.size === 'medium' ? '3em' : props.size === 'large' ? '4em' : props.size === 'small' ? '2.5em' : 'none'};
    min-height: ${props => props.size === 'medium' ? '3em' : props.size === 'large' ? '4em' : props.size === 'small' ? '2.5em' : 'none'};
    max-width: ${props => props.size === 'medium' ? '3em' : props.size === 'large' ? '4em' : props.size === 'small' ? '2.5em' : 'none'};
    max-height: ${props => props.size === 'medium' ? '3em' : props.size === 'large' ? '4em' : props.size === 'small' ? '2.5em' : 'none'};
    border-radius: 50%;
    background: ${props => props.primary ? 'tomato' : '#afd0cd'};
    display: flex;
    justify-content: center;
    align-items: center;
`
const QtyInput = styled.input`
    width: ${props => props.size === 'medium' ? '2.6em' : props.size === 'large' ? '3.6em' : props.size === 'small' ? '2em' : 'none'};
    height: ${props => props.size === 'medium' ? '2.6em' : props.size === 'large' ? '3.6em' : props.size === 'small' ? '2em' : 'none'};
    border-radius: 50%;
    border: none;
    appearance: none;
    --webkit-appearance: none;
    outline: none;
    text-align: center;
    background: #eff3f4;
    color: #838484;
`
export default function CircleNumericInput({
    defaultValue, changeFunc, primary, 
    size, disabled, value, isAllowNegative=false,
    step
}) {
    const handleChange = (e) => {
        changeFunc(+e.target.value)
    }
    return (
        <CircleQtyInputWrapper
            primary={primary}
            size={size}
        >
            <QtyInput
                defaultValue={defaultValue || value || 0}
                value={value || defaultValue || 0 }
                type={'number'}
                onChange={handleChange}
                min={isAllowNegative ? -100 : 0}
                onClick={selectOnFocus}
                size={size}
                disabled={disabled}
                step={step}
                // onWheel={(e) => e.currentTarget.blur()}
            />
        </CircleQtyInputWrapper>
    )
}

CircleNumericInput.propTypes = {
    defaultValue: PropTypes.number,
    changeFunc: PropTypes.func.isRequired,
    primary: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    disabled: PropTypes.bool
}

CircleNumericInput.defaultProps = {
    primary: false,
    onChange: undefined,
    size: 'medium',
    disabled: false,
    value: 0,
    step: undefined
}
